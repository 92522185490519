import '../../App.css';
import './Industry.css';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import IndustryCard from './IndustryCard.jsx';
import React, { useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import healthcare from '../../images/healthcare.jpg';
import government from '../../images/government.jpg';
import finance from '../../images/finance.jpg';
import retail from '../../images/retail2.jpg';
import business from '../../images/business.jpg';
import entertainment from '../../images/entertainment.jpg';
import agriculture from '../../images/agriculture.jpg';
import travel from '../../images/travel.jpg';
import { AuthContext } from '../../shared/context/auth-context';

const Industry = () => {
	const auth = useContext(AuthContext);
	const handleDragStart = e => e.preventDefault();

	const responsive = {
		0: {
			items: 1,
		},
		568: {
			items: 2,
		},
		1024: {
			items: 3,
			itemsFit: 'contain',
		},
	};

	const { sendRequest } = useHttpClient();
	const [contentData, setContentData] = useState([]);

	useEffect(() => {
		let responseData;

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/industries',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		try {
			contentHandler();
		} catch (error) {}
	}, [sendRequest, auth.token]);

	return (
		<div className='industry-container'>
			<div className='industry-title-card'>
				{contentData.map(content => (
					<>
						<div className='industry-title-content'>
							<h2 className='title'>
								{content.industriestitle}
							</h2>
							<div className='industry-subtitle-card'>
								<p>{content.industriessubtitle}</p>
							</div>
						</div>
						<div className='industry-section'>
							<h2 className='title'>
								{content.industriescardstitle}
							</h2>
							<AliceCarousel
								mouseTracking
								items={[
									<IndustryCard
										title={
											content.industriescard1title
										}
										info={
											content.industriescard1content
										}
										onDragStart={handleDragStart}
										img={healthcare}
										altInfo='doctor with arms crossed holding a stethoscope'
										data-value='1'
									/>,
									<IndustryCard
										title={
											content.industriescard2title
										}
										info={
											content.industriescard2content
										}
										onDragStart={handleDragStart}
										img={government}
										altInfo='outside view of a government building'
										data-value='2'
									/>,
									<IndustryCard
										title={
											content.industriescard3title
										}
										info={
											content.industriescard3content
										}
										onDragStart={handleDragStart}
										img={finance}
										altInfo='money from different countries laying in a pile'
										data-value='3'
									/>,
									<IndustryCard
										title={
											content.industriescard4title
										}
										info={
											content.industriescard4content
										}
										onDragStart={handleDragStart}
										img={retail}
										altInfo='a busy mall'
										data-value='4'
									/>,
									<IndustryCard
										title={
											content.industriescard5title
										}
										info={
											content.industriescard5content
										}
										onDragStart={handleDragStart}
										img={business}
										altInfo='a large business meeting in a conference room'
										data-value='5'
									/>,
									<IndustryCard
										title={
											content.industriescard6title
										}
										info={
											content.industriescard6content
										}
										onDragStart={handleDragStart}
										img={entertainment}
										altInfo='a crowd with their arms raised at a concert'
										data-value='6'
									/>,
									<IndustryCard
										title={
											content.industriescard7title
										}
										info={
											content.industriescard7content
										}
										onDragStart={handleDragStart}
										img={agriculture}
										altInfo='upclose view of baby plants growing in a field'
										data-value='7'
									/>,
									<IndustryCard
										title={
											content.industriescard8title
										}
										info={
											content.industriescard8content
										}
										onDragStart={handleDragStart}
										img={travel}
										altInfo='the view from an airplane window with clouds and the wing'
										data-value='8'
									/>,
								]}
								responsive={responsive}
							/>
						</div>
					</>
				))}
			</div>
		</div>
	);
};

export default Industry;
