import React from 'react';
import './EmployeeSideNavFull.css';
import { EmployeeSideNavFullData } from '../EmployeeSideNavData/EmployeeSideNavFullData.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import logo from '../../../../images/Favicon.png';
import EmployeeSubMenu from '../EmployeeSubMenu/EmployeeSubMenu.jsx';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const EmployeeSideNavFull = () => {
	const [employeeSidenav, setEmployeeSidenav] = useState(false);
	const showEmployeeSidenav = () => setEmployeeSidenav(!employeeSidenav);

	return (
		<>
			<div className='employee-sidenav-bar'>
				<Link to='#' className='employee-sidenav-menu-bars'>
					<MenuIcon onClick={showEmployeeSidenav} />
				</Link>
			</div>
			<div
				className={
					employeeSidenav
						? 'employee-sidenav active'
						: 'employee-sidenav'
				}>
				<ul
					className='employee-sidenav-menu'
					onClick={showEmployeeSidenav}>
					<li className='employee-sidenav-toggle'>
						<Link
							to='#'
							className='employee-sidenav-menu-bars'>
							<CloseIcon />
						</Link>
					</li>
					{EmployeeSideNavFullData.map((item, index) => {
						return (
							<EmployeeSubMenu item={item} key={index} />
						);
					})}

					<li
						className='employee-sidenav-menu-item'
						id='employee-sidenav-menu-settings'>
						<span className='employee-sidenav-menu-settings-icon'>
							<Link to='/employee/settings'>
								<SettingsIcon />
							</Link>
						</span>
					</li>
					<li>
						<div className='dash-footer-logo'>
							<Link to='/employee/home'>
								<img src={logo}></img>
							</Link>
							<p>
								&copy;2022 Decentralized Technologies,
								LLC
							</p>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default EmployeeSideNavFull;
