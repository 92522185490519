import '../EditSite.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../../../UI/Modal/ErrorModal';
import { AuthContext } from '../../../../../../shared/context/auth-context';

const ContactUsContent = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		contactTitle: '',
	});

	useEffect(() => {
		let responseData;
		const contactContentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/contact',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
			} catch (error) {}
			setValues({
				contactTitle: responseData.content[0].contactustitle,
			});
		};

		contactContentHandler();
	}, [sendRequest, auth.token]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const handleContentUpdate = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				'https://decentralizedtech.org/api/contents/updateContact',
				'PUT',
				JSON.stringify({
					contactustitle: values.contactTitle,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/edit-site');
		} catch (err) {}
	};

	return (
		<form onSubmit={handleContentUpdate}>
			<ErrorModal error={error} onClear={clearError} />
			<div className='input-group'>
				<span className='input-group-text'>Title</span>
				<textarea
					className='form-control'
					name='contactTitle'
					value={values.contactTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<button type='submit'>Submit</button>
		</form>
	);
};

export default ContactUsContent;
