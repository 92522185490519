import React, { useContext, useState } from 'react';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../../../../UI/Modal/ErrorModal.jsx';
import PasswordChecklist from 'react-password-checklist';
import './Settings.css';
import { AuthContext } from '../../../../../shared/context/auth-context';

const Settings = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		email: '',
		newPswd: '',
		newPswdAgain: '',
	});

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const changePasswordHandler = async event => {
		if (values.newPswd.match(values.newPswdAgain)) {
			event.preventDefault();
			try {
				await sendRequest(
					`https://decentralizedtech.org/api/users/update/` +
						values.email,
					'PUT',
					JSON.stringify({
						password: values.newPswd,
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					}
				);
				nav('/admin/home');
			} catch (err) {}
		}
	};

	return (
		<>
			<ErrorModal error={error} onClear={clearError} />
			<div className='settings'>
				<div className='settings-container'>
					<div className='settings-card'>
						<h2>Change Password</h2>
						<form
							className='change-pswd-form'
							onSubmit={changePasswordHandler}>
							<div>
								<label>Email: </label>
								<input
									onChange={onChangeHandler}
									name='email'
									title='email'
									type='email'
									placeholder=''
									required
								/>
							</div>
							<div>
								<label>New Password: </label>
								<input
									onChange={onChangeHandler}
									name='newPswd'
									title='newPswd'
									type='password'
									placeholder=''
									required
								/>
							</div>
							<div>
								<label>Re-Enter New Password: </label>
								<input
									onChange={onChangeHandler}
									name='newPswdAgain'
									title='newPswdAgain'
									type='password'
									placeholder=''
									required
								/>
							</div>
							<PasswordChecklist
								rules={[
									'minLength',
									'specialChar',
									'number',
									'capital',
									'match',
								]}
								minLength={8}
								value={values.newPswd}
								valueAgain={values.newPswdAgain}
								onChange={isValid => {}}
								style={{
									width: '90%',
									margin: 'auto',
									marginBottom: '30px',
								}}
							/>
							<button
								type='submit'
								onClick={changePasswordHandler}>
								Submit
							</button>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default Settings;
