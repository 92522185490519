import '../EditSite.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../../../UI/Modal/ErrorModal';
import { AuthContext } from '../../../../../../shared/context/auth-context';

const LoginContent = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		loginTitle: '',
	});

	useEffect(() => {
		let responseData;
		const loginContentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/login',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
			} catch (error) {}
			setValues({ loginTitle: responseData.content[0].logintitle });
		};

		loginContentHandler();
	}, [sendRequest, auth.token]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const handleContentUpdate = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				'https://decentralizedtech.org/api/contents/updateLogin',
				'PUT',
				JSON.stringify({
					logintitle: values.loginTitle,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/edit-site');
		} catch (err) {}
	};

	return (
		<form onSubmit={handleContentUpdate}>
			<ErrorModal error={error} onClear={clearError} />
			<div className='input-group'>
				<span className='input-group-text'>Title</span>
				<textarea
					className='form-control'
					name='loginTitle'
					value={values.loginTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<button type='submit'>Submit</button>
		</form>
	);
};

export default LoginContent;
