import './ContactUs.css';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ContactCard from '../../images/ContactCard.png';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorModal from '../../UI/Modal/ErrorModal';
import { AuthContext } from '../../shared/context/auth-context';

const siteKey = process.env.REACT_APP_SITE_KEY;
const secretKey = process.env.REACT_APP_SECRET_KEY;

const ContactUs = () => {
	const [recaptchaValue, setRecaptchaValue] = useState(false);
	const recaptchaRef = useRef(null);
	const onChange = async value => {
		let tokenResponse;
		try {
			tokenResponse = await sendRequest(
				'https://decentralizedtech.org/api/users/captcha/',
				'POST',
				JSON.stringify({
					token: value,
					key: secretKey,
				}),
				{ 'Content-Type': 'application/json' }
			);
			tokenResponse = tokenResponse.verification;
		} catch (err) {
			console.log('Error: ', err);
		}

		if (tokenResponse) {
			setRecaptchaValue(tokenResponse);
		}
	};

	const nav = useNavigate();
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const { error, clearError } = useHttpClient();
	const [values, setValues] = useState({
		FirstName: '',
		LastName: '',
		Email: '',
		PhoneNumber: '',
		CompanyName: '',
		Message: '',
	});
	const [contentData, setContentData] = useState([]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	useEffect(() => {
		let responseData;

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/contact',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		try {
			contentHandler();
		} catch (error) {}
	}, [sendRequest, auth.token, recaptchaRef]);

	const onSubmitHandler = async event => {
		event.preventDefault();
		if (recaptchaValue) {
			try {
				await sendRequest(
					`https://decentralizedtech.org/api/requests`,
					'POST',
					JSON.stringify({
						firstName: values.FirstName,
						lastName: values.LastName,
						email: values.Email,
						phoneNumber: values.PhoneNumber,
						companyName: values.CompanyName,
						message: values.Message,
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					}
				);
				nav('/');
			} catch (err) {}
		} else {
			alert('Please verify your identity using the reCaptcha!');
		}
	};

	const addDashesToPhoneNumber = event => {
		let x = event.target.value
			.replace(/\D/g, '')
			.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		event.target.value = !x[2]
			? x[1]
			: '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
	};

	return (
		<>
			<ErrorModal error={error} onClear={clearError} />
			{contentData.map(content => (
				<>
					<div className='contact-title' key={content.id}>
						<h2>{content.contactustitle}</h2>
					</div>
				</>
			))}
			<div
				className='section'
				style={{ backgroundImage: `url${ContactCard}` }}>
				<div className='contact-container'>
					<img src={ContactCard} alt='Contact Card' />
				</div>
				<form
					className='box'
					action='https://formspree.io/f/xnqlgane'
					method='POST'
					onSubmit={onSubmitHandler}>
					<div className='field is-centered'>
						<label className='label'>First Name</label>
						<div className='control'>
							<input
								onChange={onChangeHandler}
								className='input'
								type='text'
								placeholder=''
								name='FirstName'
								required
							/>
						</div>
					</div>
					<div className='field is-centered'>
						<label className='label'>Last Name</label>
						<div className='control'>
							<input
								onChange={onChangeHandler}
								className='input'
								type='text'
								placeholder=''
								name='LastName'
								required
							/>
						</div>
					</div>
					<div className='field is-centered'>
						<label className='label'>Company Name</label>
						<div className='control'>
							<input
								onChange={onChangeHandler}
								className='input'
								type='text'
								placeholder=''
								name='CompanyName'
								required
							/>
						</div>
					</div>
					<div className='field is-centered'>
						<label className='label'>Email</label>
						<div className='control'>
							<input
								onChange={onChangeHandler}
								className='input'
								type='email'
								id='email'
								value={values.Email}
								placeholder=''
								name='Email'
								required
							/>
						</div>
					</div>
					<div className='field is-centered'>
						<label className='label' for='phone'>
							Phone Number
						</label>
						<div className='control'>
							<input
								onChange={onChangeHandler}
								className='input'
								type='tel'
								placeholder=''
								name='PhoneNumber'
								onInput={addDashesToPhoneNumber}
								required
							/>
						</div>
					</div>
					<div className='field'>
						<label className='label'>Message</label>
						<div className='control'>
							<textarea
								onChange={onChangeHandler}
								className='textarea'
								placeholder=''
								name='Message'
								required
							/>
						</div>
					</div>
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey={siteKey}
						onChange={onChange}
					/>
					<div className='mt-6 has-text-centered'>
						<button className='button is-warning'>
							Submit
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default ContactUs;
