import React from 'react';
import './ClientSideNavFull.css';
import { ClientSideNavFullData } from '../ClientSideNavData/ClientSideNavFullData.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import logo from '../../../../images/Favicon.png';
import ClientSubMenu from '../ClientSubMenu/ClientSubMenu.jsx';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const ClientSideNavFull = () => {
	const [clientSidenav, setClientSidenav] = useState(false);
	const showClientSidenav = () => setClientSidenav(!clientSidenav);

	return (
		<>
			<div className='client-sidenav-bar'>
				<Link to='#' className='client-sidenav-menu-bars'>
					<MenuIcon onClick={showClientSidenav} />
				</Link>
			</div>
			<div
				className={
					clientSidenav
						? 'client-sidenav active'
						: 'client-sidenav'
				}>
				<ul
					className='client-sidenav-menu'
					onClick={showClientSidenav}>
					<li className='client-sidenav-toggle'>
						<Link to='#' className='client-sidenav-menu-bars'>
							<CloseIcon />
						</Link>
					</li>
					{ClientSideNavFullData.map((item, index) => {
						return <ClientSubMenu item={item} key={index} />;
					})}

					<li
						className='client-sidenav-menu-item'
						id='client-sidenav-menu-settings'>
						<span className='client-sidenav-menu-settings-icon'>
							<Link to='/client/settings'>
								<SettingsIcon />
							</Link>
						</span>
					</li>
					<li>
						<div className='dash-footer-logo'>
							<Link to='/client/home'>
								<img src={logo}></img>
							</Link>
							<p>
								&copy;2022 Decentralized Technologies,
								LLC
							</p>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default ClientSideNavFull;
