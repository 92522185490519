import '../EditSite.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../../../UI/Modal/ErrorModal';
import { AuthContext } from '../../../../../../shared/context/auth-context';

const IndustriesContent = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		industriesTitle: '',
		industriesSubtitle: '',
		industriesCardsTitle: '',
		industriesCard1Title: '',
		industriesCard1Content: '',
		industriesCard2Title: '',
		industriesCard2Content: '',
		industriesCard3Title: '',
		industriesCard3Content: '',
		industriesCard4Title: '',
		industriesCard4Content: '',
		industriesCard5Title: '',
		industriesCard5Content: '',
		industriesCard6Title: '',
		industriesCard6Content: '',
		industriesCard7Title: '',
		industriesCard7Content: '',
		industriesCard8Title: '',
		industriesCard8Content: '',
	});

	useEffect(() => {
		let responseData;
		const industriesContentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/industries',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
			} catch (error) {}
			setValues({
				industriesTitle: responseData.content[0].industriestitle,
				industriesSubtitle:
					responseData.content[0].industriessubtitle,
				industriesCardsTitle:
					responseData.content[0].industriescardstitle,
				industriesCard1Title:
					responseData.content[0].industriescard1title,
				industriesCard1Content:
					responseData.content[0].industriescard1content,
				industriesCard2Title:
					responseData.content[0].industriescard2title,
				industriesCard2Content:
					responseData.content[0].industriescard2content,
				industriesCard3Title:
					responseData.content[0].industriescard3title,
				industriesCard3Content:
					responseData.content[0].industriescard3content,
				industriesCard4Title:
					responseData.content[0].industriescard4title,
				industriesCard4Content:
					responseData.content[0].industriescard4content,
				industriesCard5Title:
					responseData.content[0].industriescard5title,
				industriesCard5Content:
					responseData.content[0].industriescard5content,
				industriesCard6Title:
					responseData.content[0].industriescard6title,
				industriesCard6Content:
					responseData.content[0].industriescard6content,
				industriesCard7Title:
					responseData.content[0].industriescard7title,
				industriesCard7Content:
					responseData.content[0].industriescard7content,
				industriesCard8Title:
					responseData.content[0].industriescard8title,
				industriesCard8Content:
					responseData.content[0].industriescard8content,
			});
		};

		industriesContentHandler();
	}, [sendRequest, auth.token]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const updateContent = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				'https://decentralizedtech.org/api/contents/updateIndustries',
				'PUT',
				JSON.stringify({
					industriestitle: values.industriesTitle,
					industriessubtitle: values.industriesSubtitle,
					industriescardstitle: values.industriesCardsTitle,
					industriescard1title: values.industriesCard1Title,
					industriescard1content: values.industriesCard1Content,
					industriescard2title: values.industriesCard2Title,
					industriescard2content: values.industriesCard2Content,
					industriescard3title: values.industriesCard3Title,
					industriescard3content: values.industriesCard3Content,
					industriescard4title: values.industriesCard4Title,
					industriescard4content: values.industriesCard4Content,
					industriescard5title: values.industriesCard5Title,
					industriescard5content: values.industriesCard5Content,
					industriescard6title: values.industriesCard6Title,
					industriescard6content: values.industriesCard6Content,
					industriescard7title: values.industriesCard7Title,
					industriescard7content: values.industriesCard7Content,
					industriescard8title: values.industriesCard8Title,
					industriescard8content: values.industriesCard8Content,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/edit-site');
		} catch (err) {}
	};

	return (
		<form onSubmit={updateContent}>
			<ErrorModal error={error} onClear={clearError} />
			<div className='input-group'>
				<span className='input-group-text'>Title</span>
				<textarea
					className='form-control'
					name='industriesTitle'
					value={values.industriesTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Sub-Title</span>
				<textarea
					className='form-control'
					name='industriesSubtitle'
					value={values.industriesSubtitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Cards Title</span>
				<textarea
					className='form-control'
					name='industriesCardsTitle'
					value={values.industriesCardsTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 1</span>
				<input
					type='text'
					id='cardTitle1'
					name='industriesCard1Title'
					value={values.industriesCard1Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard1Content'
					value={values.industriesCard1Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 2</span>
				<input
					type='text'
					id='cardTitle2'
					name='industriesCard2Title'
					value={values.industriesCard2Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard2Content'
					value={values.industriesCard2Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 3</span>
				<input
					type='text'
					id='cardTitle3'
					name='industriesCard3Title'
					value={values.industriesCard3Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard3Content'
					value={values.industriesCard3Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 4</span>
				<input
					type='text'
					id='cardTitle4'
					name='industriesCard4Title'
					value={values.industriesCard4Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard4Content'
					value={values.industriesCard4Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 5</span>
				<input
					type='text'
					id='cardTitle5'
					name='industriesCard5Title'
					value={values.industriesCard5Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard5Content'
					value={values.industriesCard5Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 6</span>
				<input
					type='text'
					id='cardTitle6'
					name='industriesCard6Title'
					value={values.industriesCard6Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard6Content'
					value={values.industriesCard6Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 7</span>
				<input
					type='text'
					id='cardTitle7'
					name='industriesCard7Title'
					value={values.industriesCard7Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard7Content'
					value={values.industriesCard7Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 8</span>
				<input
					type='text'
					id='cardTitle8'
					name='industriesCard8Title'
					value={values.industriesCard8Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='industriesCard8Content'
					value={values.industriesCard8Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<button type='submit' onClick={updateContent}>
				Submit
			</button>
		</form>
	);
};

export default IndustriesContent;
