import { useCallback, useEffect, useState } from 'react';

let logoutTimer;

export const useAuth = () => {
	const [token, setToken] = useState(false);
	const [tokenExpirationDate, setTokenExpirationDate] = useState();
	const [userId, setUserId] = useState(false);
	const [email, setEmail] = useState(false);
	const [role, setRole] = useState('');

	const login = useCallback((uid, email, token, role, expirationDate) => {
		setToken(token);
		setEmail(email);
		setUserId(uid);
		setRole(role);
		const expireDate =
			expirationDate ||
			new Date(new Date().getTime() + 1000 * 4 * 3600);
		setTokenExpirationDate(expirationDate);
		localStorage.setItem(
			'userData',
			JSON.stringify({
				userId: uid,
				email: email,
				role: role,
				token: token,
				expiration: expireDate.toISOString(),
			})
		);
	}, []);

	const logout = useCallback(() => {
		setToken(null);
		setTokenExpirationDate(null);
		setEmail(null);
		setRole(null);
		setUserId(null);
		localStorage.removeItem('userData');
	}, []);

	useEffect(() => {
		if (token && tokenExpirationDate) {
			const remainingTime =
				tokenExpirationDate.getTime() - new Date().getTime();
			logoutTimer = setTimeout(logout, remainingTime);
		} else {
			clearTimeout(logoutTimer);
		}
	}, [token, logout, tokenExpirationDate]);

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem('userData'));
		if (
			storedData &&
			storedData.token &&
			new Date(storedData.expiration) > new Date()
		) {
			login(
				storedData.userId,
				storedData.token,
				storedData.email,
				storedData.role,
				new Date(storedData.expiration)
			);
		}
	}, [login]);

	return { token, login, logout, email, role, userId };
};
