import './Industry.css';

const IndustryCard = props => {
	return (
		<div className='industry-card' data-value={props.dataVal}>
			<div className='industry-card-content'>
				<img
					className='industry-img'
					src={props.img}
					alt={props.altinfo}></img>
				<h3>{props.title}</h3>
				<p>{props.info}</p>
			</div>
		</div>
	);
};

export default IndustryCard;
