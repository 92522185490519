import React from 'react';
import ClientSideNavFull from './ClientSideNav/ClientSideNavFull/ClientSideNavFull.jsx';
import { Routes, Route } from 'react-router-dom';
import Home from './ClientSideNav/Sections/Home/Home.jsx';
import Settings from './ClientSideNav/Sections/Settings/Settings.jsx';
import Projects from './ClientSideNav/Sections/Projects/Projects.jsx';
import PastProjects from './ClientSideNav/Sections/Projects/PastProjects.jsx';
import './ClientDash.css';

const ClientDashHome = () => {
	return (
		<>
			<div
				className='client-container'
				style={{
					width: '-webkit-fill-available',
					position: 'absolute',
					height: '-webkit-fill-available',
				}}>
				<Routes>
					<Route path='/home' element={<Home />} />
					<Route path='/projects' element={<Projects />} />
					<Route
						path='/projects/past-projects'
						element={<PastProjects />}
					/>
					<Route path='/settings' element={<Settings />} />
				</Routes>
			</div>
			<ClientSideNavFull />
		</>
	);
};

export default ClientDashHome;
