import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import './EmployeeSubMenu.css';

const EmployeeSubMenu = ({ item }) => {
	const [subnav, setSubnav] = useState(false);

	const showSubnav = () => setSubnav(!subnav);
	return (
		<>
			<Link
				to={`/employee${item.link}`}
				onClick={item.subNav && showSubnav}
			>
				<li
					className={
						item.iconOpened && item.subNav && subnav
							? 'employee-sidenav-menu-item-active'
							: 'employee-sidenav-menu-item'
					}
				>
					<div className='employee-sidenav-menu-item-icon'>
						{item.icon}
						<span className='employee-sidenav-menu-item-text'>
							{item.name}
						</span>
					</div>
					<div className='employee-sidenav-menu-item-icon'>
						{item.subNav && subnav
							? item.iconOpened
							: item.subNav
							? item.iconClosed
							: null}
					</div>
				</li>
			</Link>
			{subnav &&
				item.subNav.map((item, index) => {
					return (
						<Link
							to={`/employee${item.link}`}
							key={index}
							className='employee-sidenav-menu-item-children'
						>
							{item.icon}

							<div className='employee-sidenav-menu-item-children-text'>
								{item.name}
							</div>
						</Link>
					);
				})}
		</>
	);
};
export default EmployeeSubMenu;
