import React, { useState, useEffect, useContext } from 'react';
import './JobOpenings.css';
import CreateJobPosting from './CreateJobPosting.jsx';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../shared/context/auth-context';

const JobOpenings = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [jobsData, setJobsData] = useState([]);
	const nav = useNavigate();

	useEffect(() => {
		let responseData;
		const jobsHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/jobs/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setJobsData(responseData.jobs);
			} catch (error) {}
		};

		jobsHandler();
	}, [sendRequest, auth.token]);

	const [isForm, setIsForm] = useState(false);

	const handleDeleteJob = async (event, jobId) => {
		event.preventDefault();
		try {
			await sendRequest(
				`https://decentralizedtech.org/api/jobs/` + jobId,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			nav('/admin/home');
		} catch (err) {}
	};

	const switchToForm = () => {
		setIsForm(!isForm);
	};

	// State variable to keep track of all the expanded rows
	// By default, nothing expanded. Hence initialized with empty array.
	const [expandedRows, setExpandedRows] = useState([]);

	// State variable to keep track which row is currently expanded.
	const [expandState, setExpandState] = useState({});

	/**
	 * This function gets called when show/hide link is clicked.
	 */
	const handleExpandRow = (event, jobId) => {
		const currentExpandedRows = expandedRows;
		const isRowExpanded = currentExpandedRows.includes(jobId);

		let obj = {};
		isRowExpanded ? (obj[jobId] = false) : (obj[jobId] = true);
		setExpandState(obj);

		// If the row is expanded, we are here to hide it. Hence remove
		// it from the state variable. Otherwise add to it.
		const newExpandedRows = isRowExpanded
			? currentExpandedRows.filter(id => id !== jobId)
			: currentExpandedRows.concat(jobId);

		setExpandedRows(newExpandedRows);
	};

	return (
		<div className='job-openings'>
			<div className='job-openings-container'>
				<div className='job-openings-card'>
					{isForm.valueOf(true) ? (
						<CreateJobPosting></CreateJobPosting>
					) : (
						<>
							<h2>Job Openings</h2>
							{jobsData.map(job => (
								<>
									<div
										className='job-content'
										key={job.job_id}>
										<ul>
											<li className='job-position'>
												{job.job_title}
											</li>
											<li className='job-desc'>
												{
													job.job_description
												}
											</li>
											<li className='job-expand'>
												<button
													onClick={event =>
														handleExpandRow(
															event,
															job.job_id
														)
													}>
													{expandState[
														job.job_id
													] ? (
														<span className='material-icons'>
															cancel
														</span>
													) : (
														<span className='material-icons'>
															expand_circle_down
														</span>
													)}
												</button>
											</li>
											<li className='job-delete'>
												<button
													onClick={event =>
														handleDeleteJob(
															event,
															job.job_id
														)
													}>
													<span className='material-icons'>
														delete_outline
													</span>
												</button>
											</li>
										</ul>
									</div>
									<>
										{expandedRows.includes(
											job.job_id
										) ? (
											<div className='jobs-expanded-content'>
												<ul>
													<li className='job-resp mobile-desc'>
														Description:{' '}
														{
															job.job_description
														}
													</li>
													<li className='job-resp'>
														Responsibilities:{' '}
														{job.responsibilities.toString()}
													</li>
													<li className='job-qual'>
														Qualifications:{' '}
														{job.qualifications.toString()}
													</li>
												</ul>
											</div>
										) : null}
									</>
								</>
							))}
						</>
					)}
					<button className='addJobBtn' onClick={switchToForm}>
						{isForm.valueOf(true) ? (
							<span className='material-icons'>close</span>
						) : (
							<span className='material-icons'>add</span>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

export default JobOpenings;
