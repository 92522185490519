import '../../App.css';
import './Footer.css';
import twitter from '../../images/twitter.png';
import linkedin from '../../images/linkedin.png';
import logo from '../../images/Favicon.png';
import { Link } from 'react-router-dom';
import React from 'react';

const Footer = () => {
	return (
		<footer>
			<div className='footer-row'>
				<div className='footer-links-container'>
					<div className='footer-links'>
						<ul className='nav-links'>
							<li>
								<Link to='/AboutUs'>About Us</Link>
							</li>
							
							<li>
								<Link to='/ContactUs'>Contact Us</Link>
							</li>
							
							<li>
								<Link to='/Services'>Services</Link>
							</li>
							
							<li>
								<Link to='/Careers'>Careers</Link>
							</li>
							
							<li>
								<Link to='/LogIn'>Login</Link>
							</li>
							
						</ul>
					</div>
					
				</div>
				<div className='footer-logo'>
					<Link to='/'>
						<img src={logo} alt='DT logo'></img>
						<p>Decentralized Technologies</p>
					</Link>
				</div>
			</div>
			<div className='footer-copyright'>
				<ul className='social-links'>
					<li>
						<a href='https://www.linkedin.com/'>
							<img src={linkedin}></img>
						</a>
					</li>
					<li>
						<a href='https://twitter.com/'>
							<img src={twitter}></img>
						</a>
					</li>
				</ul>
				<p>&copy;2022 Decentralized Technologies, LLC</p>
			</div>
		</footer>
	);
};

export default Footer;
