import React, { useContext, useState } from 'react';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../../../../UI/Modal/ErrorModal.jsx';
import { AuthContext } from '../../../../../shared/context/auth-context';

const CreateJobPosting = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		positionName: '',
		description: '',
		qualifications: {},
		responsibilities: {},
	});

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const createPostingHandler = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				`https://decentralizedtech.org/api/jobs/create`,
				'POST',
				JSON.stringify({
					job_title: values.positionName,
					job_description: values.description,
					qualifications: values.qualifications,
					responsibilities: values.responsibilities,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/home');
		} catch (err) {}
	};

	return (
		<>
			<ErrorModal error={error} onClear={clearError} />
			<h2>Create a Job</h2>
			<form
				className='create-job-form'
				onSubmit={createPostingHandler}>
				<div>
					<label>Job Title: </label>
					<textarea
						onChange={onChangeHandler}
						name='positionName'
						title='positionName'
						placeholder=''
						required
					/>
				</div>
				<div>
					<label>Description: </label>
					<textarea
						onChange={onChangeHandler}
						name='description'
						title='description'
						placeholder=''
						required
					/>
				</div>
				<div>
					<label>Responsibilities: </label>
					<textarea
						onChange={onChangeHandler}
						name='responsibilities'
						title='responsibilities'
						placeholder=''
						required
					/>
				</div>
				<div>
					<label>Qualifications: </label>
					<textarea
						onChange={onChangeHandler}
						name='qualifications'
						title='qualifications'
						placeholder=''
						required
					/>
				</div>
				<button type='submit' onClick={createPostingHandler}>
					Create Job
				</button>
			</form>
		</>
	);
};

export default CreateJobPosting;
