import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const EmployeeSideNavFullData = [
	{
		name: 'Home',
		icon: <HomeIcon />,
		link: '/home',
		section: 'home',
	},
	{
		name: 'Projects',
		icon: <FolderIcon />,
		link: '/projects',
		section: 'projects',
		iconOpened: <ArrowDropUpIcon />,
		iconClosed: <ArrowDropDownIcon />,

		subNav: [
			// {
			// 	name: 'Create Project',
			// 	icon: <AddToPhotosIcon />,
			// 	link: '/projects/create-project',
			// },
			{
				name: 'Past Projects',
				icon: <AddToPhotosIcon />,
				link: '/projects/past-projects',
			},
		],
	},
];
