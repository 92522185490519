import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../shared/context/auth-context';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import './Home.css';

const Home = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { sendRequest } = useHttpClient();
	const [projectData, setProjectData] = useState([]);
	const [requestsData, setRequestsData] = useState([]);
	const [invoicesData, setInvoicesData] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);

	useEffect(() => {
		let responseData;
		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/projects/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};

		const requestHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/requests/status/received`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setRequestsData(responseData.request);
			} catch (error) {}
		};

		const invoiceHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/invoices/unpaid`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setInvoicesData(responseData.unpaidInvoices);
			} catch (error) {}
		};

		const employeeHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/employees`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setEmployeeData(responseData.employees);
			} catch (error) {}
		};

		projectHandler();
		requestHandler();
		invoiceHandler();
		employeeHandler();
	}, [sendRequest, auth.token]);

	//Accept Requests Handler
	const requestStatusApprovedHandler = async (event, request) => {
		event.preventDefault();

		let request_id = request.request_id;
		let first_name = request.first_name;
		let last_name = request.last_name;
		let _email = request.request_email;
		let company_name = request.company_name;
		let phoneNumber = request.phone_number;

		try {
			await sendRequest(
				`https://decentralizedtech.org/api/requests/` + request_id,
				'PUT',
				JSON.stringify({
					status: 'approved',
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);

			await sendRequest(
				`https://decentralizedtech.org/api/users/register`,
				'POST',
				JSON.stringify({
					firstName: first_name,
					lastName: last_name,
					email: _email,
					phoneNumber: phoneNumber,
					role: 'customer',
					companyName: company_name,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
		} catch (err) {}
		nav('/admin/projects/request-project');
	};

	//Deny Requests Handler
	const requestStatusDeniedHandler = async (event, request_id) => {
		event.preventDefault();
		try {
			await sendRequest(
				`https://decentralizedtech.org/api/requests/` + request_id, // which endpoint does this need??
				'PUT', //or UPDATE?
				JSON.stringify({
					status: 'rejected',
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
		} catch (err) {}
		nav('/admin/projects');
	};

	const projectList = projectData.map(project => {
		if (
			project.status === 'created' ||
			project.status === 'in progress'
		) {
			let employeeName = '';
			const employeesArray = employeeData;
			employeesArray.forEach(employee => {
				if (employee.employee_id === project.employee_id) {
					employeeName =
						employee.first_name + ' ' + employee.last_name;
				}
			});
			let date = '';
			if (project.due_date != null) {
				date = project.due_date.substring(
					0,
					project.due_date.indexOf('T')
				);
			}
			return (
				<li className='home-list-item' key={project.project_id}>
					<div className='home-list-title'>
						<div className='home-list-section'>
							<h3>{project.project_name}</h3>
						</div>
					</div>
					<div className='home-divider-box'>
						<div className='home-list-divider'>
							<div className='home-list-section'>
								<h4>
									{project.first_name}{' '}
									{project.last_name}
								</h4>
							</div>
							<div className='home-list-section'>
								<h4>{date}</h4>
							</div>
						</div>
						<div className='home-list-divider'>
							<div className='home-list-section'>
								<h4>{employeeName}</h4>
							</div>
							<div className='home-list-section'>
								<h4>{project.status}</h4>
							</div>
						</div>
					</div>
				</li>
			);
		}
		return null;
	});

	const requestList = requestsData.map(request => {
		if (request.status === 'received') {
			return (
				<li className='request-list-item' key={request.request_id}>
					<div className='request-list-section one'>
						<h3>
							{request.first_name} {request.last_name}
						</h3>
					</div>
					<div className='request-list-section two'>
						<button
							className='request-btn'
							onClick={event =>
								requestStatusApprovedHandler(
									event,
									request
								)
							}
							value='approved'>
							<span className='material-icons request'>
								check_circle
							</span>
						</button>
						<button
							className='request-btn'
							onClick={event =>
								requestStatusDeniedHandler(
									event,
									request.request_id
								)
							}
							value='rejected'>
							<span className='material-icons request'>
								cancel
							</span>
						</button>
					</div>
				</li>
			);
		}
		return null;
	});

	const invoiceList = invoicesData.map(invoice => (
		<li className='request-list-item' key={invoice.invoice_id}>
			<div className='request-list-section one'>
				<h3>
					{invoice.first_name} {invoice.last_name}
				</h3>
			</div>
			<div className='request-list-section two'>
				<h4>${invoice.total_amount}</h4>
			</div>
		</li>
	));

	return (
		<div className='home-container-admin'>
			<div className='home-dash-container'>
				<div className='home-dash-card'>
					<h2>Current Project Status</h2>
					<div className='list-card'>
						<ul className='home-list'>{projectList}</ul>
					</div>
				</div>
				<div className='home-card-row'>
					<div className='half-card'>
						<h2>Requests</h2>
						<div className='list-card'>
							<ul className='home-list'>{requestList}</ul>
						</div>
					</div>
					<div className='half-card'>
						<h2>Invoices</h2>
						<div className='list-card'>
							<ul className='home-list'>{invoiceList}</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
