import React from 'react';
import EmployeeSideNavFull from '../EmployeeDash/EmployeeSideNav/EmployeeSideNavFull/EmployeeSideNavFull.jsx';
import { Routes, Route } from 'react-router-dom';
import Home from './EmployeeSideNav/Sections/Home/Home.jsx';
import Settings from './EmployeeSideNav/Sections/Settings/Settings.jsx';
import Projects from './EmployeeSideNav/Sections/Projects/Projects.jsx';
import PastProjects from './EmployeeSideNav/Sections/Projects/PastProjects.jsx';
import CreateProject from './EmployeeSideNav/Sections/Projects/CreateProject.jsx';
import './EmployeeDash.css';

const EmployeeDashHome = () => {
	return (
		<>
			<div
				className='employee-container'
				style={{
					width: '-webkit-fill-available',
					position: 'absolute',
					height: '-webkit-fill-available',
				}}>
				<Routes>
					<Route path='/home' element={<Home />} />
					<Route path='/projects' element={<Projects />} />
					<Route path='/settings' element={<Settings />} />
					<Route
						path='/projects/past-projects'
						element={<PastProjects />}
					/>
					<Route
						path='/projects/create-project'
						element={<CreateProject />}
					/>
				</Routes>
			</div>
			<EmployeeSideNavFull />
		</>
	);
};

export default EmployeeDashHome;
