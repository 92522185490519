import "./EditSite.css";
import HomeContent from "./SiteForms/HomeContent.jsx";
import AboutUsContent from "./SiteForms/AboutUsContent.jsx";
import ServicesContent from "./SiteForms/ServicesContent.jsx";
import IndustriesContent from "./SiteForms/IndustriesContent.jsx";
import CareersContent from "./SiteForms/CareersContent.jsx";
import ContactUsContent from "./SiteForms/ContactUsContent.jsx";
import React, { useState } from 'react';
import LoginContent from "./SiteForms/LoginContent";

const EditSite = () => {
  // return <div className="edit-site">Edit Site</div>;
  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);

  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});

  const sites = [
    { name: "Home/Landing", link: <HomeContent /> },
    { name: "About Us", link: <AboutUsContent /> },
    { name: "Services", link: <ServicesContent /> },
    { name: "Industries", link: <IndustriesContent /> },
    { name: "Careers", link: <CareersContent /> },
    { name: "Contact", link: <ContactUsContent />}, 
    { name: "Login", link: <LoginContent />}
  ];

  /**
   * This function gets called when show/hide link is clicked.
   */
  const handleExpandRow = (event, siteId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(siteId);

    let obj = {};
    isRowExpanded ? (obj[siteId] = false) : (obj[siteId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((id) => id !== siteId)
      : currentExpandedRows.concat(siteId);

    setExpandedRows(newExpandedRows);
  };
  
  return (
    <div className="edit-site">
      <div className="edit-site-container">
        <div className="edit-site-card">
          <h2>Edit Website Content</h2> 
          {/* <table> */}
            {sites.map((val, key) => (
              <>
                {/* <tr key={key} className="table-headers">
                  <td>
                    <button onClick={(event) => handleExpandRow(event, key)}>
                      {val.name}
                    </button>
                  </td>
                </tr> */}
                <div key={key} className="table-headers">
                  <ul>
                    <li className="header-title">{val.name}</li>
                    <li className="header-button"><button onClick={(event) => handleExpandRow(event, key)}>
                    {expandedRows.includes(key) ? <span className="material-icons">cancel</span>
													: <span className="material-icons">expand_circle_down</span>}
                    </button></li>
                  </ul>
                </div>
                <>
                  {/* {expandedRows.includes(key) ? (
                    <tr className="siteContent">
                      <td className="siteForm">
                        {val.link}
                      </td>
                    </tr>
                  ) : null} */}
                  {expandedRows.includes(key) ? (
                    <div className="siteContent">
                      <ul>
                      <li className="siteForm">
                        {val.link}
                      </li></ul>
                    </div>
                  ) : null}
                </>
              </>
            ))}
          {/* </table> */}
        </div>
      </div>
    </div>
  );
};

export default EditSite;
