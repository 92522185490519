import '../EditSite.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../../../UI/Modal/ErrorModal';
import { useContext } from 'react';
import { AuthContext } from '../../../../../../shared/context/auth-context';

const AboutUsContent = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		aboutUsTitle: '',
		aboutUsSubtitle: '',
		aboutUsRow1: '',
		aboutUsRow2: '',
	});

	useEffect(() => {
		let responseData;
		const aboutUsContentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/about',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
			} catch (error) {}
			setValues({
				aboutUsTitle: responseData.content[0].aboutustitle,
				aboutUsSubtitle: responseData.content[0].aboutussubtitle,
				aboutUsRow1: responseData.content[0].aboutusrow1,
				aboutUsRow2: responseData.content[0].aboutusrow2,
			});
		};

		aboutUsContentHandler();
	}, [sendRequest, auth.token]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const updateContent = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				'https://decentralizedtech.org/api/contents/updateAbout',
				'PUT',
				JSON.stringify({
					aboutustitle: values.aboutUsTitle,
					aboutussubtitle: values.aboutUsSubtitle,
					aboutusrow1: values.aboutUsRow1,
					aboutusrow2: values.aboutUsRow2,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/edit-site');
		} catch (err) {}
	};

	return (
		<form onSubmit={updateContent}>
			<ErrorModal error={error} onClear={clearError} />
			<div className='input-group'>
				<span className='input-group-text'>Title</span>
				<textarea
					className='form-control'
					name='aboutUsTitle'
					value={values.aboutUsTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Sub-Title</span>
				<textarea
					className='form-control'
					name='aboutUsSubtitle'
					value={values.aboutUsSubtitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Content of Row 1</span>
				<textarea
					className='form-control'
					name='aboutUsRow1'
					value={values.aboutUsRow1}
					onChange={onChangeHandler}></textarea>
			</div>
			{/* <div className="input-group">
                <span className="input-group-text">Content of Row 2</span>
                <textarea
                    className="form-control"
                    name='aboutUsRow2'
                    value={values.aboutUsRow2}
                    onChange={onChangeHandler}
                ></textarea>
            </div> */}
			<button type='submit' onClick={updateContent}>
				Submit
			</button>
		</form>
	);
};

export default AboutUsContent;
