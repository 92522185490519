import React, { useContext, useRef, useState } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../UI/Modal/ErrorModal';
import ReCAPTCHA from 'react-google-recaptcha';
import './JobApplication.css';
import { AuthContext } from '../../shared/context/auth-context';

const siteKey = process.env.REACT_APP_SITE_KEY;
const secretKey = process.env.REACT_APP_SECRET_KEY;

const JobApplication = props => {
	const [recaptchaValue, setRecaptchaValue] = useState(false);
	const recaptchaRef = useRef();
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		firstName: '',
		lastName: '',
		email: '',
		resume: null,
	});

	const [files, setFiles] = useState();

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
		setFiles(event.target.files[0]);
	};

	const onChange = async value => {
		let tokenResponse;
		try {
			tokenResponse = await sendRequest(
				'https://decentralizedtech.org/api/users/captcha/',
				'POST',
				JSON.stringify({
					token: value,
					key: secretKey,
				}),
				{ 'Content-Type': 'application/json' }
			);
			tokenResponse = tokenResponse.verification;
		} catch (err) {
			console.log('Error: ', err);
		}

		if (tokenResponse) {
			setRecaptchaValue(tokenResponse);
		}
	};

	const backToHome = event => {
		nav('/');
	};

	const sendResume = async event => {
		event.preventDefault();
		if (recaptchaValue) {
			let formData = new FormData();
			formData.append('file', files);
			formData.append('firstName', values.firstName);
			formData.append('lastName', values.lastName);
			formData.append('applicantEmail', values.email);
			formData.append('jobTitle', props.positionName);
			try {
				await sendRequest(
					`https://decentralizedtech.org/api/jobs/apply`,
					'POST',
					formData,
					{ Authorization: 'Bearer ' + auth.token }
				);

				nav('/careers');
			} catch (err) {}
		} else {
			alert('Please verify your identity using the reCaptcha!');
		}
	};

	return (
		<>
			<ErrorModal error={error} onClear={clearError} />
			<h2 className='job-app-title'>Job Application</h2>
			<form className='job-apply-form' onSubmit={sendResume}>
				<div>
					<label>First Name: </label>
					<input
						onChange={onChangeHandler}
						name='firstName'
						title='firstName'
						placeholder=''
						type='text'
						required
					/>
				</div>
				<div>
					<label>Last Name: </label>
					<input
						onChange={onChangeHandler}
						name='lastName'
						title='lastName'
						placeholder=''
						type='text'
						required
					/>
				</div>
				<div>
					<label>Email: </label>
					<input
						onChange={onChangeHandler}
						name='email'
						title='email'
						placeholder=''
						type='email'
						required
					/>
				</div>
				<div>
					<label>Resume: </label>
					<input
						onChange={onChangeHandler}
						name='resume'
						title='resume'
						placeholder=''
						type='file'
						accept='.doc, .docx, .pdf'
						required
					/>
				</div>
				<ReCAPTCHA
					ref={recaptchaRef}
					sitekey={siteKey}
					onChange={onChange}
				/>
				<button type='submit' onClick={backToHome}>
					Back
				</button>
				<button type='submit' onClick={sendResume}>
					Submit
				</button>
			</form>
		</>
	);
};

export default JobApplication;
