import { React, useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../../shared/context/auth-context';
import './PastProjects.css';

const PastProjects = () => {
	const { sendRequest } = useHttpClient();
	const [projectData, setProjectData] = useState([]);
	const [clientId, setClientId] = useState();
	const auth = useContext(AuthContext);
	const [employeeData, setEmployeeData] = useState([]);

	useEffect(() => {
		let responseData;
		const clientHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/customers/id/` +
						auth.userId,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setClientId(responseData.customer.customer_id);
			} catch (error) {}
		};
		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/projects/customer/` +
						clientId,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};
		const employeeHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/employees`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setEmployeeData(responseData.employees);
			} catch (error) {}
		};
		clientHandler();
		projectHandler();
		employeeHandler();
	}, [sendRequest, clientId, auth.userId, auth.token]);

	const pastProjectList = projectData.map(project => {
		if (project.status === 'completed' || project.status === 'closed') {
			let employeeName = '';
			employeeData.forEach(employee => {
				if (employee.employee_id === project.employee_id) {
					employeeName =
						employee.first_name + ' ' + employee.last_name;
				}
			});
			let date = '';
			if (project.due_date != null) {
				date = project.due_date.substring(
					0,
					project.due_date.indexOf('T')
				);
			}
			return (
				<li
					className='past-project-list-item'
					key={project.project_id}>
					<div className='past-project-title-divider'>
						<div className='past-project-list-section'>
							<h3>{project.project_name}</h3>
						</div>
						<div className='past-project-list-section'>
							<h4>{project.description}</h4>
						</div>
					</div>
					<div className='past-project-divider-box'>
						<div className='past-project-divider'>
							<div className='past-project-list-section'>
								<h4>{project.status}</h4>
							</div>
							<div className='past-project-list-section'>
								<h4>{date}</h4>
							</div>
						</div>
						<div className='past-project-divider'>
							<div className='past-project-list-section'>
								<h4>{employeeName}</h4>
							</div>
						</div>
					</div>
				</li>
			);
		}
		return null;
	});

	return (
		<div className='past-projects'>
			<div className='past-projects-container'>
				<div className='past-projects-card'>
					<h2>Past Projects</h2>
					<div className='past-project-list-card'>
						<ul className='past-projects-list'>
							{pastProjectList}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PastProjects;
