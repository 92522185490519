import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Employees.css';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { useContext } from 'react';
import { AuthContext } from '../../../../../shared/context/auth-context';

const Employees = () => {
	//request data to propogate employees
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [employeeData, setEmployeeData] = useState([]);
	const [projectData, setProjectData] = useState([]);
	const nav = useNavigate();

	useEffect(() => {
		let responseData;
		const employeeHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/employees/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setEmployeeData(responseData.employees);
			} catch (error) {}
		};

		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/projects/assigned`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};

		employeeHandler();
		projectHandler();
	}, [sendRequest, auth.token]);

	const handleDeleteEmployee = async (event, employeeId) => {
		event.preventDefault();
		let userIdResponse;
		try {
			userIdResponse = await sendRequest(
				`https://decentralizedtech.org/api/users/employeeId/` + employeeId, 'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token });
			
			await sendRequest(
				`https://decentralizedtech.org/api/users/` + userIdResponse.userId,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			nav('/admin/home');
		} catch (err) {}
	};

	//map individual employee info
	const employeeList = employeeData.map(employee => (
		<li className='employee-list-item' key={employee.employee_id}>
			<div className='employee-title-divider'>
				<div className='employee-list-section'>
					<h3>
						{employee.first_name} {employee.last_name}
					</h3>
				</div>
			</div>
			<div className='employee-divider-box'>
				<div className='employee-divider'>
					<div className='employee-list-section'>
						<h4>{employee.email}</h4>
					</div>
					<div className='employee-list-section'>
						<h4>{employee.phone_number}</h4>
					</div>
				</div>
				<div className='employee-project-divider'>
					<div className='employee-list-section'>
						<h4>
							{projectData.map(item =>
								item.first_name.match(
									employee.first_name
								) &&
								item.last_name.match(
									employee.last_name
								) ? (
									<li
										className='pl-item'
										key={item.project_id}>
										<h4>{item.project_name}</h4>
									</li>
								) : null
							)}
						</h4>
					</div>
					<div className='employee-list-section'>
						<h4>
							{projectData.map(item =>
								item.first_name.match(
									employee.first_name
								) &&
								item.last_name.match(
									employee.last_name
								) ? (
									<li
										className='pl-item'
										key={item.project_id}>
										<h4>{item.status}</h4>
									</li>
								) : null
							)}
						</h4>
					</div>
				</div>
				<li className='job-delete'>
					<button
						onClick={event =>
							handleDeleteEmployee(
								event,
								employee.employee_id
							)
						}>
						<span className='material-icons'>
							delete_outline
						</span>
					</button>
				</li>
			</div>
		</li>
	));

	return (
		<div className='employees'>
			<div className='employees-container'>
				<div className='employees-card'>
					<div className='employees-card-header'>
						<h2>Employees</h2>
					</div>
					<div className='list-card'>
						<ul className='employees-list'>{employeeList}</ul>
					</div>
					<div className='employee-card-footer'>
						<Link
							to='/admin/createemployee'
							className='create-employee-button'>
							+
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Employees;
