import '../../App.css';
import './Careers.css';
import React, { useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import JobApplication from './JobApplication.jsx';
import { AuthContext } from '../../shared/context/auth-context';

const Careers = () => {
	const auth = useContext(AuthContext);
	const [isForm, setIsForm] = useState(false);
	const [positionTitle, setPositionTitle] = useState('');

	const { sendRequest } = useHttpClient();
	const [jobsData, setJobsData] = useState([]);
	const [contentData, setContentData] = useState([]);

	useEffect(() => {
		let responseData;
		const jobsHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/jobs/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setJobsData(responseData.jobs);
			} catch (error) {}
		};

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/contents/careers`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		contentHandler();
		jobsHandler();
	}, [sendRequest, auth.token]);

	// State variable to keep track of all the expanded rows
	// By default, nothing expanded. Hence initialized with empty array.
	const [expandedRows, setExpandedRows] = useState([]);

	// State variable to keep track which row is currently expanded.
	const [expandState, setExpandState] = useState({});

	/**
	 * This function gets called when show/hide link is clicked.
	 */
	const handleExpandRow = (event, jobId) => {
		const currentExpandedRows = expandedRows;
		const isRowExpanded = currentExpandedRows.includes(jobId);

		let obj = {};
		isRowExpanded ? (obj[jobId] = false) : (obj[jobId] = true);
		setExpandState(obj);

		// If the row is expanded, we are here to hide it. Hence remove
		// it from the state variable. Otherwise add to it.
		const newExpandedRows = isRowExpanded
			? currentExpandedRows.filter(id => id !== jobId)
			: currentExpandedRows.concat(jobId);

		setExpandedRows(newExpandedRows);
	};

	const handleApply = (event, position) => {
		setPositionTitle(position);
		setIsForm(!isForm);
	};

	return (
		<div className='careers-container'>
			<div className='careers-title-card'>
				<div className='careers-title-content'>
					{isForm.valueOf(true) ? (
						<JobApplication
							positionName={
								positionTitle
							}></JobApplication>
					) : (
						<>
							{contentData.map(content => (
								<>
									<h2 className='career-title'>
										{content.careerstitle}
									</h2>
									<p>{content.careerssubtitle}</p>
								</>
							))}
							<div className='careers-card'>
								<table
									data-aos='zoom-in'
									data-aos-duration='1500'>
									<tr>
										<th>Job Title</th>
										<th>Description</th>
										<th>Show More</th>
									</tr>
									{jobsData.map(job => (
										<>
											<tr key={job.job_id}>
												<td>
													{job.job_title}
												</td>
												<td>
													{
														job.job_description
													}
												</td>
												<td>
													<button
														onClick={event =>
															handleExpandRow(
																event,
																job.job_id
															)
														}>
														{expandState[
															job
																.job_id
														]
															? 'Hide'
															: 'Show'}
													</button>
												</td>
											</tr>
											<>
												{expandedRows.includes(
													job.job_id
												) ? (
													<tr className='postingDetails'>
														<td></td>
														<td className='postingContent'>
															<div>
																<ul>
																	<li>
																		<span>
																			<b>
																				Position
																				Responsibilities:
																			</b>
																		</span>{' '}
																		<span>
																			{' '}
																			{job.responsibilities.toString()}{' '}
																		</span>
																	</li>
																	<li>
																		<span>
																			<b>
																				Desired
																				Qualifications:
																			</b>
																		</span>{' '}
																		<span>
																			{' '}
																			{job.qualifications.toString()}{' '}
																		</span>
																	</li>
																</ul>
															</div>
														</td>
														<td>
															<button
																onClick={event =>
																	handleApply(
																		event,
																		job.job_title
																	)
																}>
																Apply
															</button>
														</td>
													</tr>
												) : null}
											</>
										</>
									))}
								</table>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Careers;
