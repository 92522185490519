import React from 'react';

import Modal from './Modal.jsx';

const ErrorModal = props => {
	return (
		<>
		{props.error === 'Fetch is aborted' ? null :
		<Modal
			onCancel={props.onClear}
			header='An Error Occurred!'
			show={!!props.error}
			footer={<button onClick={props.onClear}>Okay</button>}>
			<p style={{ color: 'red' }}>{props.error}</p>
		</Modal>}</>
	);
};

export default ErrorModal;
