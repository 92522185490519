import '../../App.css';
import './Landing.css';
import contract from '../../images/contract-min.jpg';
import connected from '../../images/connected-min.jpg';
import blocks from '../../images/blocks2.jpg';
import { Link } from 'react-router-dom';
import { ThreeDSceneLogo } from '../../3d-logo/3d-scene-logo.jsx';
import React, { useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';

const Landing = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [contentData, setContentData] = useState([]);

	useEffect(() => {
		let responseData;

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/landing',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		try {
			contentHandler();
		} catch (error) {}
	}, [sendRequest, auth.token]);

	return (
		<React.Fragment>
			<div className='home-container'>
				{contentData.map(content => (
					<>
						<div className='home-card-container'>
							<div className='card'>
								<div className='content'>
									<h2>{content.landingtitle}</h2>
									<p>{content.landingsubtitle}</p>
									<Link
										to='/ContactUs'
										className='contactBtn'>
										{content.landingbtn}
									</Link>
								</div>
							</div>
							<div className='animated-logo'>
								<ThreeDSceneLogo />
							</div>
						</div>

						<div className='home-section'>
							<div className='home-card'>
								<div className='home-card-content'>
									<img
										className='landing-img'
										src={connected}
										alt='hand holding mobile phone'></img>
									<Link
										to='/Services'
										className='home-card-link'>
										<h3>
											{
												content.landingcard1title
											}
										</h3>
									</Link>
									<div className='home-card-text truncate-overflow'>
										<p>
											{
												content.landingcard1content
											}
										</p>
									</div>
								</div>
							</div>
							<div className='home-card'>
								<div className='home-card-content'>
									<img
										className='landing-img'
										src={blocks}
										alt='hand holding mobile phone'></img>
									<Link
										to='/Services'
										className='home-card-link'>
										<h3>
											{
												content.landingcard2title
											}
										</h3>
									</Link>
									<div className='home-card-text truncate-overflow'>
										<p>
											{
												content.landingcard2content
											}
										</p>
									</div>
								</div>
							</div>

							<div className='home-card'>
								<div className='home-card-content'>
									<img
										className='landing-img'
										src={contract}
										alt='hand holding mobile phone'></img>
									<Link
										to='/Services'
										className='home-card-link'>
										<h3>
											{
												content.landingcard3title
											}
										</h3>
									</Link>
									<div className='home-card-text truncate-overflow'>
										<p>
											{
												content.landingcard3content
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					</>
				))}
			</div>
		</React.Fragment>
	);
};

export default Landing;
