import React, { useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../../shared/context/auth-context';
import '../Projects/Projects.css';

const Home = () => {
	const { sendRequest } = useHttpClient();
	const [projectData, setProjectData] = useState([]);
	const [invoicesData, setInvoicesData] = useState([]);
	const [employeeId, setEmployeeId] = useState();
	const auth = useContext(AuthContext);

	useEffect(() => {
		let responseData;

		const employeeHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/employees/` +
						auth.userId,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setEmployeeId(responseData.employee.employee_id);
			} catch (error) {}
		};

		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/projects/employee/' +
						employeeId,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};

		const invoiceHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/employees/invoices/' +
						employeeId,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setInvoicesData(responseData.invoices);
			} catch (error) {}
		};

		employeeHandler();
		projectHandler();
		invoiceHandler();
	}, [sendRequest, auth.userId, employeeId, auth.token]);

	const currentProjectList = projectData.map(project => {
		if (
			project.status === 'created' ||
			project.status === 'in progress'
		) {
			let date = '';
			if (project.due_date != null) {
				date = project.due_date.substring(
					0,
					project.due_date.indexOf('T')
				);
			}
			return (
				<li className='home-list-item' key={project.project_id}>
					<div className='home-list-title'>
						<div className='home-list-section'>
							<h3>{project.project_name}</h3>
						</div>
					</div>
					<div className='home-divider-box'>
						<div className='home-list-divider'>
							<div className='home-list-section'>
								<h4>
									{project.first_name}{' '}
									{project.last_name}
								</h4>
							</div>
							<div className='home-list-section'>
								<h4>{date}</h4>
							</div>
						</div>
						<div className='home-list-divider'>
							<div className='home-list-section'>
								<h4>{project.status}</h4>
							</div>
						</div>
					</div>
				</li>
			);
		}
		return null;
	});

	const invoiceList = invoicesData.map(invoice => {
		if (!invoice.paid) {
			return (
				<li className='request-list-item' key={invoice.invoice_id}>
					<div className='request-list-section one'>
						<h3>{invoice.project_name}</h3>
					</div>
					<div className='request-list-section two'>
						<h4>${invoice.total_amount}</h4>
					</div>
				</li>
			);
		}
		return null;
	});

	return (
		<div className='projects'>
			<div className='projects-container'>
				<div className='projects-card'>
					<h2>My Projects</h2>
					<div className='project-list-card'>
						<ul className='projects-list'>
							{currentProjectList}
						</ul>
					</div>
				</div>
				<div className='projects-card'>
					<h2>Payments Owed</h2>
					<div className='project-list-card'>
						<ul className='projects-list'>{invoiceList}</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
