// import "../../../App.css";
import './DashHeader.css';
import logo from '../../../images/Favicon.png';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { AuthContext } from '../../../shared/context/auth-context';

const DashHeader = () => {
    const auth = useContext(AuthContext);
    const nav = useNavigate();

    const onLogoutHandler = event => {
        event.preventDefault();
        auth.logout();
        nav('/');
    }

    return (
        <nav className="dash-navbar">
            <div className="dash-header-brand">
                <Link to="/admin/home">
                    <img src={logo}></img>
                </Link>
                <Link to="/admin/home">Decentralized Technologies</Link>
            </div>
            <h1>Dashboard</h1>
            <div className="dash-header-right">
                {auth.isLoggedIn && (
                    <li>
                        <button onClick={onLogoutHandler}>Log Out</button>
                    </li>
                )}
            </div>
        </nav>
    );
};

export default DashHeader;
