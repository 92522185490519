import React, { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { useThree } from '@react-three/fiber';

const Box = props => {
	const mesh = useRef();
	const [hovered, setHover] = useState(false);
	const [active, setActive] = useState(false);

	useFrame(() => {
		mesh.current.rotation.x = mesh.current.rotation.y += 0.01;
	});

	//make responsive
	const { size } = useThree();
	const aspect = size.width / size.height;
	//setActive(!active); -> //true
	//setActive(!active); -> //true on onPointerOver so false here
	return (
		<mesh
			{...props}
			ref={mesh}
			scale={
				active
					? [aspect * 1.5, aspect * 1.5, aspect * 1.5]
					: [aspect * 1, aspect * 1, aspect * 1]
			}
			onPointerOver={() => {
				setHover(true);
				setActive(!active);
			}}
			onPointerOut={() => {
				setActive(!active);
				setHover(false);
			}}
			style={{ position: 'relative' }}>
			<dodecahedronGeometry attach='geometry' args={[1, 0]} />
			<meshStandardMaterial
				attachArray={'material'}
				color={hovered ? 'purple' : 'orange'}
				metalness={0.5}
				roughness={1.0}
			/>
		</mesh>
	);
};

export default Box;
