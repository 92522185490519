import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import WorkIcon from '@mui/icons-material/Work';
import EditIcon from '@mui/icons-material/Edit';
import BadgeIcon from '@mui/icons-material/Badge';
import FolderIcon from '@mui/icons-material/Folder';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const SideNavFullData = [
	{
		name: 'Home',
		icon: <HomeIcon />,
		link: '/home',
		section: 'home',
	},
	{
		name: 'Projects',
		icon: <FolderIcon />,
		link: '/projects',
		section: 'projects',
		iconOpened: <ArrowDropUpIcon />,
		iconClosed: <ArrowDropDownIcon />,

		subNav: [
			{
				name: 'Create Project',
				icon: <AddToPhotosIcon />,
				link: '/projects/request-project',
			},
			{
				name: 'Past Projects',
				icon: <AddToPhotosIcon />,
				link: '/projects/past-projects',
			},
		],
	},
	{
		name: 'Clients',
		icon: <PeopleIcon />,
		link: '/clients',
		section: 'clients',
	},
	{
		name: 'Employees',
		icon: <BadgeIcon />,
		link: '/employees',
		section: 'employees',
	},
	{
		name: 'Invoices',
		icon: <BadgeIcon />,
		link: '/invoices',
		section: 'invoices',
		iconOpened: <ArrowDropUpIcon />,
		iconClosed: <ArrowDropDownIcon />,

		subNav: [
			{
				name: 'Create Invoice',
				icon: <AddToPhotosIcon />,
				link: '/invoices/create-invoice',
			},
			{
				name: 'Past Invoices',
				icon: <AddToPhotosIcon />,
				link: '/invoices/past-invoices',
			},
		],
	},
	{
		name: 'Job Openings',
		icon: <WorkIcon />,
		link: '/job-openings',
		section: 'job-openings',
	},
	{
		name: 'Edit Site',
		icon: <EditIcon />,
		link: '/edit-site',
		section: 'edit-site',
	},
];
