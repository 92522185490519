import '../../App.css';
import './Services.css';
import React, { useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import blocks from '../../images/blocks2.jpg';
import contract from '../../images/contract2-min.jpg';
import security from '../../images/security2-min.jpg';
import phone from '../../images/phone.jpg';
import { AuthContext } from '../../shared/context/auth-context';

const Services = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [contentData, setContentData] = useState([]);

	useEffect(() => {
		let responseData;

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/services',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		try {
			contentHandler();
		} catch (error) {}
	}, [sendRequest, auth.token]);

	return (
		<div className='services-container'>
			<div className='services-title-card'>
				{contentData.map(content => (
					<>
						<div className='services-title-content'>
							<h2 className='title' id='services-title'>
								{content.servicestitle}
							</h2>
							<p className='services'>
								{content.servicessubtitle}
							</p>
						</div>
						<div className='services-section'>
							<div className='services-card-row'>
								<div className='services-card'>
									<img
										className='services-img'
										src={phone}
										alt='hand holding mobile phone'></img>
									<div className='services-card-content'>
										<h3>
											{
												content.servicescard1title
											}
										</h3>
										<p>
											{
												content.servicescard1content
											}
										</p>
									</div>
								</div>
								<div className='services-card'>
									<img
										className='services-img'
										src={blocks}
										alt='digital blockchain'></img>
									<div className='services-card-content'>
										<h3>
											{
												content.servicescard2title
											}
										</h3>
										<p>
											{
												content.servicescard2content
											}
										</p>
									</div>
								</div>
							</div>
							<div className='services-card-row'>
								<div className='services-card'>
									<img
										className='services-img'
										src={security}
										alt='padlock sitting on laptop keyboard'></img>
									<div className='services-card-content'>
										<h3>
											{
												content.servicescard3title
											}
										</h3>
										<p>
											{
												content.servicescard3content
											}
										</p>
									</div>
								</div>
								<div className='services-card'>
									<img
										className='services-img'
										src={contract}
										alt='man signing documents'></img>
									<div className='services-card-content'>
										<h3>
											{
												content.servicescard4title
											}
										</h3>
										<p>
											{
												content.servicescard4content
											}
										</p>
									</div>
								</div>
							</div>
						</div>
					</>
				))}
			</div>
		</div>
	);
};

export default Services;
