import '../EditSite.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../../../UI/Modal/ErrorModal';
import { AuthContext } from '../../../../../../shared/context/auth-context';
import { useContext } from 'react';

const ServicesContent = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		servicesTitle: '',
		servicesSubtitle: '',
		servicesCard1Title: '',
		servicesCard1Content: '',
		servicesCard2Title: '',
		servicesCard2Content: '',
		servicesCard3Title: '',
		servicesCard3Content: '',
		servicesCard4Title: '',
		servicesCard4Content: '',
	});

	useEffect(() => {
		let responseData;
		const servicesContentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/services',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
			} catch (error) {}
			setValues({
				servicesTitle: responseData.content[0].servicestitle,
				servicesSubtitle: responseData.content[0].servicessubtitle,
				servicesCard1Title:
					responseData.content[0].servicescard1title,
				servicesCard1Content:
					responseData.content[0].servicescard1content,
				servicesCard2Title:
					responseData.content[0].servicescard2title,
				servicesCard2Content:
					responseData.content[0].servicescard2content,
				servicesCard3Title:
					responseData.content[0].servicescard3title,
				servicesCard3Content:
					responseData.content[0].servicescard3content,
				servicesCard4Title:
					responseData.content[0].servicescard4title,
				servicesCard4Content:
					responseData.content[0].servicescard4content,
			});
		};

		servicesContentHandler();
	}, [sendRequest, auth.token]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const updateContent = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				'https://decentralizedtech.org/api/contents/updateServices',
				'PUT',
				JSON.stringify({
					servicestitle: values.servicesTitle,
					servicessubtitle: values.servicesSubtitle,
					servicescard1title: values.servicesCard1Title,
					servicescard1content: values.servicesCard1Content,
					servicescard2title: values.servicesCard2Title,
					servicescard2content: values.servicesCard2Content,
					servicescard3title: values.servicesCard3Title,
					servicescard3content: values.servicesCard3Content,
					servicescard4title: values.servicesCard4Title,
					servicescard4content: values.servicesCard4Content,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/edit-site');
		} catch (error) {}
	};

	return (
		<form onSubmit={updateContent}>
			<ErrorModal error={error} onClear={clearError} />
			<div className='input-group'>
				<span className='input-group-text'>Title</span>
				<textarea
					className='form-control'
					name='servicesTitle'
					value={values.servicesTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Sub-Title</span>
				<textarea
					className='form-control'
					name='servicesSubtitle'
					value={values.servicesSubtitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 1</span>
				<input
					type='text'
					id='cardTitle1'
					name='servicesCard1Title'
					value={values.servicesCard1Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='servicesCard1Content'
					value={values.servicesCard1Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 2</span>
				<input
					type='text'
					id='cardTitle2'
					name='servicesCard2Title'
					value={values.servicesCard2Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='servicesCard2Content'
					value={values.servicesCard2Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 3</span>
				<input
					type='text'
					id='cardTitle3'
					name='servicesCard3Title'
					value={values.servicesCard3Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='servicesCard3Content'
					value={values.servicesCard3Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 4</span>
				<input
					type='text'
					id='cardTitle4'
					name='servicesCard4Title'
					value={values.servicesCard4Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='servicesCard4Content'
					value={values.servicesCard4Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<button type='submit' onClick={updateContent}>
				Submit
			</button>
		</form>
	);
};

export default ServicesContent;
