import { useCallback, useEffect, useRef, useState } from 'react';

export const useHttpClient = () => {
	const [error, setError] = useState();

	const activeHttpRequests = useRef([]);

	const sendRequest = useCallback(
		async (url, method = 'GET', body = null, headers = {}) => {
			const httpAbortController = new AbortController();
			activeHttpRequests.current.push(httpAbortController);

			try {
				const response = await fetch(url, {
					method,
					body,
					headers,
					signal: httpAbortController.signal,
				});

				const responseData = await response.json();

				activeHttpRequests.current =
					activeHttpRequests.current.filter(
						reqCtrl => reqCtrl !== httpAbortController
					);

				if (!response.ok) {
					throw new Error(responseData.message);
				}

				return responseData;
			} catch (err) {
				if (err.message !== 'The user aborted a request.') {
					setError(err.message);
					throw err;
				}
			}
		},
		[]
	);

	const clearError = () => {
		setError(null);
	};

	useEffect(() => {
		return () => {
			activeHttpRequests.current.forEach(abortController =>
				abortController.abort()
			);
		};
	}, []);

	return { error, sendRequest, clearError };
};
