import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Landing from './pages/Landing/Landing.jsx';
import AboutUs from './pages/AboutUs/AboutUs.jsx';
import Careers from './pages/Careers/Careers.jsx';
import ContactUs from './pages/ContactUs/ContactUs.jsx';
import LogIn from './pages/LogIn/LogIn.jsx';
import Services from './pages/Services/Services.jsx';
import Industry from './pages/Industry/Industry.jsx';
import ClientDashHome from './Dashboards/ClientDash/ClientDashHome.jsx';
import EmployeeDashHome from './Dashboards/EmployeeDash/EmployeeDashHome.jsx';
import AdminDashHome from './Dashboards/AdminDash/AdminDashHome.jsx';
import Page404 from './pages/Page404/Page404.jsx';
import Navbar from './UI/NavBar/Navbar.jsx';
import DashHeader from './Dashboards/DashUI/DashHeader/DashHeader.jsx';
import Footer from './UI/Footer/Footer.jsx';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import ScrollToTop from './UI/ScrollToTop';

function App() {
	const { token, login, logout, email, role, userId } = useAuth();
	let storedData = JSON.parse(localStorage.getItem('userData'));
	let routes;

	if (storedData !== null && new Date(storedData.expiration) < new Date()) {
		localStorage.removeItem('userData');
		storedData = null;
	}

	if (storedData === null) {
		routes = (
			<Routes>
				{/*<Route path="/admin/*" element={<AdminDashHome />} />*/}
				{/*<Route path='/client/*' element={<ClientDashHome />} />*/}
				{/*<Route path='/employee/*' element={<EmployeeDashHome />} />*/}
				<Route path='/' element={<Landing />} />
				<Route path='/AboutUs' element={<AboutUs />} />
				<Route path='/Careers' element={<Careers />} />
				<Route path='/ContactUs' element={<ContactUs />} />
				<Route path='/LogIn' element={<LogIn />} />
				<Route path='/Services' element={<Services />} />
				<Route path='/Industry' element={<Industry />} />
				<Route path='*' element={<Navigate to='/login' />} />
			</Routes>
		);
	} else {
		if (role === 'customer') {
			routes = (
				<>
					<Routes>
						<Route
							path='/client/*'
							element={<ClientDashHome />}
						/>
						<Route path='*' element={<Page404 />} />
					</Routes>
				</>
			);
		} else if (role === 'employee') {
			routes = (
				<>
					<Routes>
						<Route
							path='/employee/*'
							element={<EmployeeDashHome />}
						/>
						<Route path='*' element={<Page404 />} />
					</Routes>
				</>
			);
		} else if (role === 'admin') {
			routes = (
				<>
					<Routes>
						<Route
							path='/admin/*'
							element={<AdminDashHome />}
						/>
						<Route path='*' element={<Page404 />} />
					</Routes>
				</>
			);
		}
	}

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				token: token,
				email: email,
				userId: userId,
				role: role,
				login: login,
				logout: logout,
			}}>
			<div className='app-container'>
				<BrowserRouter>
				<ScrollToTop />
					{token ? <DashHeader /> : <Navbar />}
					{/**remove ! & uncomment line 31 for testing actual login function */}
					{/* <Navbar /> */}
					<main>{routes}</main>
					{!token ? <Footer /> : null}
					{/**null was previously DashFooter */}
					{/* <Footer /> */}
					
				</BrowserRouter>
			</div>
		</AuthContext.Provider>
	);
}

export default App;
