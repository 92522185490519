import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../shared/context/auth-context';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import './CreateEmployee.css';

const CreateEmployee = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const nav = useNavigate();
	const [info, setInfo] = useState({
		email: '',
		firstName: '',
		lastName: '',
		phone: '',
		companyName: '',
	});

	const onChangeHandler = event => {
		setInfo({ ...info, [event.target.id]: event.target.value });
	};

	const createEmployeeHandler = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				`https://decentralizedtech.org/api/users/register`,
				'POST',
				JSON.stringify({
					firstName: info.firstName,
					lastName: info.lastName,
					email: info.email,
					phoneNumber: info.phone,
					role: 'employee',
					companyName: info.companyName,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/employees');
		} catch (err) {}
	};

	return (
		<div className='create-employee'>
			<div className='create-employee-container'>
				<div className='create-employee-card'>
					<h2>Create Employee</h2>
					<div className='employee-form-container'>
						{/*add "required" to each input*/}
						<form
							className='create-employee-form'
							onSubmit={createEmployeeHandler}>
							<div className='employee-form-group'>
								<div className='employee-form-element'>
									<label
										className='em-label'
										htmlFor='firstName'>
										First Name
									</label>
									<input
										className='formControl'
										id='firstName'
										type='text'
										onChange={onChangeHandler}
									/>
								</div>
								<div className='employee-form-element'>
									<label
										className='em-label'
										htmlFor='lastName'>
										Last Name
									</label>
									<input
										className='formControl'
										id='lastName'
										type='text'
										onChange={onChangeHandler}
									/>
								</div>
							</div>
							<div className='employee-form-group'>
								<div className='employee-form-element'>
									<label
										className='em-label'
										htmlFor='email'>
										Email
									</label>
									<input
										className='formControl'
										id='email'
										type='email'
										onChange={onChangeHandler}
									/>
								</div>
								<div className='employee-form-element'>
									<label
										className='em-label'
										htmlFor='phone'>
										Phone
									</label>
									<input
										className='formControl'
										id='phone'
										type='tel'
										onChange={onChangeHandler}
									/>
								</div>
							</div>
							<div className='employee-form-group'>
								<Link
									to='/admin/employees'
									className='dashboard-btn'>
									Cancel
								</Link>
								<button
									className='dashboard-btn'
									type='submit'
									onClick={createEmployeeHandler}>
									Add
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateEmployee;
