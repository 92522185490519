import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import '../../App.css';
import './login.css';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../UI/Modal/ErrorModal';

const LogIn = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		email: '',
		password: '',
	});
	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	// function onChange(value) {
	// 	console.log('Captcha value:', value);
	// }

	//const recaptchaRef = React.createRef();
	// const recaptchaRef = React.useRef(null);

	const [contentData, setContentData] = useState([]);

	useEffect(() => {
		let responseData;

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/login',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		try {
			contentHandler();
		} catch (error) {}
	}, [sendRequest, auth.token]);

	const loginHandler = async event => {
		event.preventDefault();
		// const captchaToken = recaptchaRef.current.getValue();
		// recaptchaRef.current.reset();
		// let tokenResponse;
		// try {
		// 	tokenResponse = await sendRequest(
		// 		'https://decentralizedtech.org/api/users/captcha',
		// 		'POST',
		// 		JSON.stringify({
		// 			token: captchaToken,
		// 			key: process.env.REACT_APP_SECRET_KEY,
		// 		})
		// 	);
		// } catch (err) {
		// 	console.log(err);
		// }
		// if (tokenResponse.success) {
		let responseData;
		try {
			responseData = await sendRequest(
					`https://decentralizedtech.org/api/users/login`,
				'POST',
				JSON.stringify({
					email: values.email,
					password: values.password,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);

			let role;
			if (responseData.role === 'customer') {
				role = 'client';
			} else {
				role = responseData.role;
			}

			auth.login(
				responseData.userId,
				responseData.email,
				responseData.token,
				responseData.role
			);
			nav(`/${role}/home`);
		} catch (err) {}
		// }
		// console.log("Verification of reCAPTCHA failed.");
	};

	return (
		<>
			<ErrorModal error={error} onClear={clearError} />
			{contentData.map(content => (
				<>
					<div className='login'>
						<h2>{content.logintitle}</h2>
					</div>
				</>
			))}
			<div className='login-container'>
				<form className='login-form' onSubmit={loginHandler}>
					<div className='login-username'>
						<label>Email: </label>
						<input
							onChange={onChangeHandler}
							name='email'
							type='email'
							title='email'
							placeholder='jsmith@example.com'
							required
						/>
					</div>
					<div className='login-pswd'>
						<label>Password: </label>
						<input
							onChange={onChangeHandler}
							name='password'
							type='password'
							title='password'
							placeholder='12345'
							required
						/>
					</div>
					{/* {
						<ReCAPTCHA
							style={{ display: 'inline-block' }}
							sitekey={process.env.REACT_APP_SITE_KEY}
							ref={recaptchaRef}
							onChange={onChange}
						/>
					} */}
					<button type='submit' onClick={loginHandler}>
						Login
					</button>
				</form>
			</div>
		</>
	);
};

export default LogIn;
