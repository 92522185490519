import React from 'react';
import './SideNavFull.css';
import { SideNavFullData } from '../SideNavData/SideNavFullData.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import logo from '../../../../images/Favicon.png';
import SubMenu from '../SubMenu/SubMenu.jsx';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const SideNavFull = () => {
	const [sidenav, setSidenav] = React.useState(false);
	const showSidenav = () => setSidenav(!sidenav);

	return (
		<>
			<div className='sidenav-bar'>
				<Link to='#' className='sidenav-menu-bars'>
					<MenuIcon onClick={showSidenav} />
				</Link>
			</div>
			<div
				className={sidenav ? 'sidenav active' : 'sidenav'}
				data-testid='sideNavFull'>
				<ul className='sidenav-menu' onClick={showSidenav}>
					<li className='sidenav-toggle'>
						<Link to='#' className='sidenav-menu-bars'>
							<CloseIcon />
						</Link>
					</li>
					{SideNavFullData.map((item, index) => {
						return <SubMenu item={item} key={index} />;
					})}

					<li
						className='sidenav-menu-item'
						id='sidenav-menu-settings'>
						<span className='sidenav-menu-settings-icon'>
							<Link to='/admin/settings'>
								<SettingsIcon />
							</Link>
						</span>
					</li>
					<li>
						<div className='dash-footer-logo'>
							<Link to='/admin/home'>
								<img src={logo}></img>
							</Link>
							<p>
								&copy;2022 Decentralized Technologies,
								LLC
							</p>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default SideNavFull;
