import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../../shared/context/auth-context';
import './Clients.css';

//request data to propogate employees

const Clients = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [clientData, setClientData] = useState([]);
	const [projectData, setProjectData] = useState([]);
	const nav = useNavigate();

	useEffect(() => {
		let responseData;
		const clientHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/customers/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setClientData(responseData.customers);
			} catch (error) {}
		};

		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/projects/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};

		clientHandler();
		projectHandler();
	}, [sendRequest, auth.token]);

	const handleDeleteClient = async (event, clientId) => {
		event.preventDefault();
		let userIdResponse;
		try {
			userIdResponse = await sendRequest(
				`https://decentralizedtech.org/api/users/customerId/` + clientId, 'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token });
			
			await sendRequest(
				`https://decentralizedtech.org/api/users/` + userIdResponse.userId,
				'DELETE',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			nav('/admin/home');
		} catch (err) {}
	};

	//map individual employee info
	const clientList = clientData.map(client => (
		<li className='clients-list-item' key={client.customer_id}>
			<div className='client-title-divider'>
				<div className='client-list-section'>
					<h3>
						{client.first_name} {client.last_name}
					</h3>
				</div>
			</div>
			<div className='client-divider-box'>
				<div className='client-divider'>
					<div className='client-list-section'>
						<h4>{client.email}</h4>
					</div>
					<div className='client-list-section'>
						<h4>{client.company_name}</h4>
					</div>
					<div className='client-list-section'>
						<h4>{client.phone_number}</h4>
					</div>
				</div>
				<div className='client-project-divider'>
					<div className='client-list-section'>
						<h4>
							{projectData.map(item =>
								item.customer_id.match(
									client.customer_id
								) ? (
									<li className='pl-item'>
										<h4>{item.project_name}</h4>
									</li>
								) : null
							)}
						</h4>
					</div>
				</div>
				<li className='job-delete'>
					<button
						onClick={event =>
							handleDeleteClient(
								event,
								client.customer_id
							)
						}>
						<span className='material-icons'>
							delete_outline
						</span>
					</button>
				</li>
			</div>
		</li>
	));

	return (
		<div className='clients'>
			<div className='clients-container'>
				<div className='clients-card'>
					<div className='clients-card-header'>
						<h2>Clients</h2>
					</div>
					<div className='clients-list-card'>
						<ul className='clients-list'>{clientList}</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Clients;
