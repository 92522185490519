import './AdminDash.css';
import React from 'react';
import SideNavFull from './SideNav/SideNavFull/SideNavFull.jsx';
import { Routes, Route } from 'react-router-dom';
import Home from './SideNav/Sections/Home/Home.jsx';
import Clients from './SideNav/Sections/Clients/Clients.jsx';
import Employees from './SideNav/Sections/Employees/Employees.jsx';
import CreateEmployee from './SideNav/Sections/Employees/CreateEmployee.jsx';
import EditSite from './SideNav/Sections/EditSite/EditSite.jsx';
import JobOpenings from './SideNav/Sections/JobOpenings/JobOpenings.jsx';
import Settings from './SideNav/Sections/Settings/Settings.jsx';
import RequestProject from './SideNav/Sections/RequestProject/RequestProject.jsx';
import Projects from './SideNav/Sections/Projects/Projects.jsx';
import PastProjects from './SideNav/Sections/Projects/PastProjects.jsx';
import Invoices from './SideNav/Sections/Invoices/Invoices.jsx';
import PastInvoices from './SideNav/Sections/Invoices/PastInvoices.jsx';
import CreateInvoice from './SideNav/Sections/Invoices/CreateInvoice.jsx';

const AdminDashHome = () => {
	return (
		<>
			<div
				data-testid='adminDash'
				className='admin-container'
				style={{
					width: '-webkit-fill-available',
					position: 'absolute',
					height: '-webkit-fill-available',
					left: '0px',
					marginTop: '0px',
					marginBottom: '0px',
					marginRight: '0px'
				}}
			>
				<Routes>
					<Route path='/home' element={<Home />} />
					<Route path='/projects' element={<Projects />} />
					<Route
						path='/projects/past-projects'
						element={<PastProjects />}
					/>
					<Route
						path='/projects/request-project'
						element={<RequestProject />}
					/>
					<Route path='/clients' element={<Clients />} />
					<Route path='/employees' element={<Employees />} />
					<Route path='/invoices' element={<Invoices />} />
					<Route
						path='/invoices/create-invoice'
						element={<CreateInvoice />}
					/>
					<Route
						path='/invoices/past-invoices'
						element={<PastInvoices />}
					/>
					<Route
						path='/createemployee'
						element={<CreateEmployee />}
					/>
					<Route path='/edit-site' element={<EditSite />} />
					<Route
						path='/job-openings'
						element={<JobOpenings />}
					/>
					<Route path='/settings' element={<Settings />} />
				</Routes>
			</div>
			<SideNavFull />
		</>
	);
};

export default AdminDashHome;
