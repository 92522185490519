import React from 'react';
import { Canvas } from '@react-three/fiber';
import { Stars } from '@react-three/drei';
import Box from '../3d-logo/box.jsx';

export const ThreeDSceneLogo = () => {
	return (
		<Canvas style={{ width: '100%', position: 'relative' }}>
			<ambientLight intensity={0.3} />
			<pointLight position={[10, 10, 10]} />
			<directionalLight position={[0, 10, 0]} intensity={1.5} />
			<perspectiveCamera position={[1, 1, 5]} />
			<Stars />
			<Box position={[-0.1, 0.9, 0.3]} />
			<Box position={[-1.2, -1.2, -0.5]} />
			<Box position={[0.7, -0.7, 1.2]} />
		</Canvas>
	);
};
