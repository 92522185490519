import './RequestProject.css';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import { AuthContext } from '../../../../../shared/context/auth-context';

const RequestProject = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const navigate = useNavigate();
	const [info, setInfo] = useState({
		description: '',
		dueDate: null,
		customerId: null,
		employeeId: null,
		projectName: '',
	});

	const [employees, setEmployees] = useState([]);
	const [clients, setClients] = useState([]);

	useEffect(() => {
		let responseData;
		const clientHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/customers/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setClients(responseData.customers);
			} catch (error) {}
		};

		const employeeHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/employees/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setEmployees(responseData.employees);
			} catch (error) {}
		};

		clientHandler();
		employeeHandler();
	}, [sendRequest, auth.token]);

	//map developers and clients for dropdown selection
	//change eventually to use actual employee and client data
	const mapDevs = employees.map(dev => (
		<option value={dev.employee_id}>
			{dev.first_name} {dev.last_name}
		</option>
	));

	const mapClients = clients.map(client => (
		<option value={client.customer_id}>
			{client.first_name} {client.last_name}
		</option>
	));

	const onChangeHandler = async event => {
		setInfo({ ...info, [event.target.name]: event.target.value });
	};

	const createProjectHandler = async event => {
		event.preventDefault();
		let adminResponseId;
		try {
			adminResponseId = await sendRequest(
				`https://decentralizedtech.org/api/users/userId/` +
					auth.userId,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);

			await sendRequest(
				`https://decentralizedtech.org/api/projects/createProject`,
				'POST',
				JSON.stringify({
					description: info.description,
					dueDate: info.dueDate,
					adminId: adminResponseId.adminId,
					customerId: info.customerId,
					employeeId: info.employeeId,
					projectName: info.projectName,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			navigate('/admin/projects');
		} catch (err) {}
	};

	return (
		<div className='create-project'>
			<div className='create-project-container'>
				<div className='create-project-card'>
					<h2>Create Project</h2>
					<div className='project-form-container'>
						{/*add "required" to each input*/}
						<form
							className='create-project-form'
							onSubmit={createProjectHandler}>
							<div className='project-form-group'>
								<div className='project-form-element'>
									<label
										className='em-label'
										for='projectName'>
										Project Name
									</label>
									<input
										className='formControl'
										id='projectName'
										type='text'
										name='projectName'
										onChange={onChangeHandler}
										required
									/>
								</div>
								<div className='project-form-element'>
									<label
										className='em-label'
										for='dueDate'>
										Due Date
									</label>
									<input
										className='formControl'
										id='dueDate'
										type='date'
										name='dueDate'
										onChange={onChangeHandler}
										required
									/>
								</div>
							</div>
							<div className='project-form-group'>
								<div className='project-form-element'>
									<label
										className='em-label'
										for='description'>
										Description:
									</label>
									<textarea
										className='formControlArea'
										id='description'
										rows='3'
										name='description'
										onChange={onChangeHandler}
										required
									/>
								</div>
							</div>
							<div className='project-form-group project-form-group-three'>
								<div className='project-form-element-selector'>
									<label
										className='em-label selector'
										for='project-drp'>
										Select Client:
									</label>
									<select
										className='project-drp'
										name='customerId'
										onChange={onChangeHandler}
										required>
										<option value=''>
											Select...
										</option>
										{mapClients}
									</select>
								</div>
								<div className='project-form-element-selector'>
									<label
										className='em-label selector'
										for='project-drp'>
										Select Developer:
									</label>
									<select
										className='project-drp'
										name='employeeId'
										onChange={onChangeHandler}>
										<option value=''>
											Select...
										</option>
										{mapDevs}
									</select>
								</div>
							</div>
							<div className='project-form-group'>
								<Link
									to='/admin/projects'
									className='dashboard-btn'>
									Cancel
								</Link>
								<button
									className='dashboard-btn'
									type='submit'>
									Add
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);

	/*return (
    <div className="create-project">
      <div className="create-project-container">
          <div className="create-project-card">
            <h2>Create Project</h2>
            <div className="form-container">

              <form className="create-project-form" onSubmit={createProjectHandler}>
                <div className="project-form-group">
                  <div className="project-form-element">
                    <label className="em-label" for="projectName">Project Name</label>
                    <input className="formControl" id="projectName" type="text" name="projectName"  onChange={onChangeHandler} required />
                  </div>
                  <div className="project-form-element">
                    <label className="em-label" for="dueDate">Due Date</label>
                    <input className="formControl" id="dueDate" type="date" name="dueDate" onChange={onChangeHandler} required />
                  </div>
                </div>
                <div className="project-form-group">
                  <div className="project-form-element">
                    <label className="em-label" for="description">Description:</label>
                    <textarea className="formControlArea" id="description" rows='3' name="description" onChange={onChangeHandler} required />
                  </div>
                </div>
                <div className="project-form-group project-form-group-three">
                  <div className="project-form-element-selector">
                    <label className="em-label selector" for="project-drp">Select Client:</label>
                    <select className="project-drp" name='customerId' onChange={onChangeHandler} required >
                      <option value="">Select...</option>
                      {mapClients}
                    </select>
                  </div>
                  <div className="project-form-element-selector">
                    <label className="em-label selector" for="project-drp">Select Developer:</label>
                    <select className="project-drp" name='employeeId' onChange={onChangeHandler} >
                      <option value="">Select...</option>
                      {mapDevs}
                    </select>
                  </div>
                </div>
                <div className="project-form-group">
                    <Link to="/admin/projects" className="dashboard-btn">Cancel</Link>
                    <button className="dashboard-btn" type="submit">Add</button>
                </div>
              </form>
            </div>
          </div>
      </div>
    </div>
  );*/
};
export default RequestProject;
