import '../EditSite.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../../shared/hooks/http-hook';
import ErrorModal from '../../../../../../UI/Modal/ErrorModal';
import { AuthContext } from '../../../../../../shared/context/auth-context';

const HomeContent = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { error, sendRequest, clearError } = useHttpClient();
	const [values, setValues] = useState({
		landingTitle: '',
		landingSubtitle: '',
		landingBtn: '',
		landingCard1Title: '',
		landingCard1Content: '',
		landingCard2Title: '',
		landingCard2Content: '',
		landingCard3Title: '',
		landingCard3Content: '',
	});

	useEffect(() => {
		let responseData;
		const landingContentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/landing',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
			} catch (error) {}
			setValues({
				landingTitle: responseData.content[0].landingtitle,
				landingSubtitle: responseData.content[0].landingsubtitle,
				landingBtn: responseData.content[0].landingbtn,
				landingCard1Title:
					responseData.content[0].landingcard1title,
				landingCard1Content:
					responseData.content[0].landingcard1content,
				landingCard2Title:
					responseData.content[0].landingcard2title,
				landingCard2Content:
					responseData.content[0].landingcard2content,
				landingCard3Title:
					responseData.content[0].landingcard3title,
				landingCard3Content:
					responseData.content[0].landingcard3content,
			});
		};

		landingContentHandler();
	}, [sendRequest, auth.token]);

	const onChangeHandler = event => {
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const handleContentUpdate = async event => {
		event.preventDefault();
		try {
			await sendRequest(
				'https://decentralizedtech.org/api/contents/updateLanding',
				'PUT',
				JSON.stringify({
					landingtitle: values.landingTitle,
					landingsubtitle: values.landingSubtitle,
					landingbtn: values.landingBtn,
					landingcard1title: values.landingCard1Title,
					landingcard1content: values.landingCard1Content,
					landingcard2title: values.landingCard2Title,
					landingcard2content: values.landingCard2Content,
					landingcard3title: values.landingCard3Title,
					landingcard3content: values.landingCard3Content,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			nav('/admin/edit-site');
		} catch (err) {}
	};

	return (
		<form onSubmit={handleContentUpdate}>
			<ErrorModal error={error} onClear={clearError} />
			<div className='input-group'>
				<span className='input-group-text'>Title</span>
				<textarea
					className='form-control'
					name='landingTitle'
					value={values.landingTitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Sub-Title</span>
				<textarea
					className='form-control'
					name='landingSubtitle'
					value={values.landingSubtitle}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 1</span>
				<input
					type='text'
					id='cardTitle1'
					name='landingCard1Title'
					value={values.landingCard1Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='landingCard1Content'
					value={values.landingCard1Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 2</span>
				<input
					type='text'
					id='cardTitle2'
					name='landingCard2Title'
					value={values.landingCard2Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='landingCard2Content'
					value={values.landingCard2Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<div className='input-group'>
				<span className='input-group-text'>Card 3</span>
				<input
					type='text'
					id='cardTitle3'
					name='landingCard3Title'
					value={values.landingCard3Title}
					onChange={onChangeHandler}></input>
				<textarea
					className='form-control'
					name='landingCard3Content'
					value={values.landingCard3Content}
					onChange={onChangeHandler}></textarea>
			</div>
			<button type='submit' onClick={handleContentUpdate}>
				Submit
			</button>
		</form>
	);
};

export default HomeContent;
