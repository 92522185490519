import "../../App.css";
import "./Navbar.css";
import logo from "../../images/Favicon.png";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../shared/context/auth-context";

const Navbar = () => {
  const auth = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const links = ["Home", "About Us", "Services", "Industries", "Careers", "Contact Us"];

  function handleClick(e) {
    
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <>
    <div className='large-screen'>
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to="/">
            <img src={logo} alt="logo"></img>
          </Link>
          <Link to="/">Decentralized Technologies</Link>
        </div>
        <div className="navbar-pages">
          <ul>
            <li className="navbar-divider">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/AboutUs">About Us</Link>
              </li>
              <li>
                <Link to="/Services">Services</Link>
              </li>
              <li>
                <Link to="/Industry">Industries</Link>
              </li>
            </li>
            <li className="navbar-divider">
              <li>
                <Link to="/Careers">Careers</Link>
              </li>
              <li>
                <Link to="/ContactUs">Contact Us</Link>
              </li>
              {!auth.isLoggedIn && (
                <li>
                  <Link to="/LogIn" className="loginBtn">
                    Log In
                  </Link>
                </li>
              )}
            </li>
            
          </ul>
        </div>
      </nav>
    </div>

    <div className='small-screen'>
      <nav className="navbar">
        <div className="navbar-brand">
          <Link to="/">
            <img src={logo} alt="logo"></img>
          </Link>
          <Link to="/">Decentralized Technologies</Link>
        </div>
        <div className="navbar-pages">
        <button id='navbar-dropdown-btn' type="submit" onClick={() => setOpen(open => !open)}><span className="material-icons navbar-icon">menu</span></button>
          {open && 
          <div className='dropdown-menu'>
          <ul>
            <li>
              <Link to="/" onClick={()=>setOpen(!open)}>Home</Link>
            </li>
            <li>
              <Link to="/AboutUs" onClick={()=>setOpen(!open)}>About Us</Link>
            </li>
            <li>
              <Link to="/Services" onClick={()=>setOpen(!open)}>Services</Link>
            </li>
            <li>
              <Link to="/Industry" onClick={()=>setOpen(!open)}>Industries</Link>
            </li>
            <li>
              <Link to="/Careers" onClick={()=>setOpen(!open)}>Careers</Link>
            </li>
            <li>
              <Link to="/ContactUs" onClick={()=>setOpen(!open)}>Contact Us</Link>
            </li>
            {!auth.isLoggedIn && (
              <li>
                <Link to="/LogIn" className="loginBtn" onClick={()=>setOpen(!open)}>
                  Log In
                </Link>
              </li>
            )}
          </ul>
          </div>
          }
        </div>
      </nav>
    </div>
    </>
  );
};

export default Navbar;
