import { React, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../shared/context/auth-context';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import './PastInvoices.css';

const PastInvoices = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [invoicesData, setInvoicesData] = useState([]);

	useEffect(() => {
		let responseData;

		const invoiceHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/invoices/paid`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setInvoicesData(responseData.paidInvoices);
			} catch (error) {}
		};

		invoiceHandler();
	}, [sendRequest, auth.token]);

	const pastInvoiceList = invoicesData.map(invoice => (
		<li className='past-invoices-list-item' key={invoice.invoice_id}>
			<div className='past-invoice-divider-box'>
				<div className='past-invoice-divider'>
					<div className='past-invoices-list-section one'>
						<h3>
							{invoice.first_name} {invoice.last_name}
						</h3>
					</div>
					<div className='past-invoices-list-section two'>
						<h4>{invoice.project_name}</h4>
					</div>
					<div className='past-invoices-list-section three'>
						<h4>${invoice.total_amount}</h4>
					</div>
				</div>
			</div>
		</li>
	));

	return (
		<div className='past-invoices'>
			<div className='past-invoices-container'>
				<div className='past-invoices-card'>
					<h2>Past Invoices</h2>
					<div className='past-invoice-list-card'>
						<ul className='past-invoices-list'>
							{pastInvoiceList}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PastInvoices;
