import '../../App.css';
import './AboutUs.css';
import aboutImg1 from '../../images/blockchain2.jpg';
import React, { useState, useEffect, useContext } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';
import aboutImg2 from '../../images/ds logo horiz.png';
import { AuthContext } from '../../shared/context/auth-context';

const AboutUs = () => {
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const [contentData, setContentData] = useState([]);

	useEffect(() => {
		let responseData;

		const contentHandler = async () => {
			try {
				responseData = await sendRequest(
					'https://decentralizedtech.org/api/contents/about',
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setContentData(responseData.content);
			} catch (error) {}
		};

		try {
			contentHandler();
		} catch (error) {}
	}, [sendRequest, auth.token]);

	return (
		<div class='about-container'>
			<div class='about-title-card'>
				{contentData.map(content => (
					<>
						<div class='about-title-content'>
							<h2 class='title' id='about-us-title'>
								{content.aboutustitle}
							</h2>
							<p class='about-content'>
								{content.aboutussubtitle}
							</p>
						</div>
						<div class='about-section'>
							<div
								className='card-row'
								data-aos='zoom-in'
								data-aos-duration='1500'>
								<div class='about-section-img'>
									<img src={aboutImg1}></img>
								</div>
								<div class='about-section-content'>
									<p>{content.aboutusrow1}</p>
								</div>
							</div>
							<div
								className='card-row'
								data-aos='zoom-in'
								data-aos-duration='1500'>
								<div class='about-section-img-2'>
									<img src={aboutImg2}></img>
								</div>
							</div>
						</div>
					</>
				))}
			</div>
		</div>
	);
};

export default AboutUs;
