import { React, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../shared/context/auth-context';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import './Projects.css';

const Projects = () => {
	const auth = useContext(AuthContext);
	const nav = useNavigate();
	const { sendRequest } = useHttpClient();
	const [projectData, setProjectData] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);

	useEffect(() => {
		let responseData;
		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/projects/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};

		const employeeHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/employees/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setEmployeeData(responseData.employees);
			} catch (error) {}
		};

		projectHandler();
		employeeHandler();
	}, [sendRequest, auth.token]);

	//handle dev update
	const onDevChangeHandler = async (event, project, currentEmployee) => {
		if (currentEmployee === null || currentEmployee === '') {
			try {
				await sendRequest(
					`https://decentralizedtech.org/api/projects/assign/` +
						project,
					'PUT',
					JSON.stringify({
						employeeId: event.target.value,
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					}
				);
			} catch (err) {}
		} else {
			try {
				await sendRequest(
					`https://decentralizedtech.org/api/projects/update/assign/` +
						project,
					'PUT',
					JSON.stringify({
						employeeId: event.target.value,
					}),
					{
						'Content-Type': 'application/json',
						Authorization: 'Bearer ' + auth.token,
					}
				);
			} catch (err) {}
		}
		nav('/admin/home');
	};

	const onStatusChangeHandler = async (event, project) => {
		try {
			await sendRequest(
				`https://decentralizedtech.org/api/projects/update/` +
					event.target.value,
				'PUT',
				JSON.stringify({
					projectId: project,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
		} catch (err) {}
		nav('/admin/home');
	};

	//TO DO: change to filter then map
	const currentProjectList = projectData.map(project => {
		if (
			project.status === 'created' ||
			project.status === 'in progress'
		) {
			let employeeName = '';
			employeeData.forEach(employee => {
				if (employee.employee_id === project.employee_id) {
					employeeName =
						employee.first_name + ' ' + employee.last_name;
				}
			});
			let date = '';
			if (project.due_date != null) {
				date = project.due_date.substring(
					0,
					project.due_date.indexOf('T')
				);
			}

			return (
				<li className='project-list-item' key={project.project_id}>
					<div className='project-title-divider'>
						<div className='project-list-section'>
							<h3>{project.project_name}</h3>
						</div>
						<div className='project-list-section'>
							<h4>{project.description}</h4>
						</div>
					</div>
					<div className='project-divider-box'>
						<div className='project-divider'>
							<div className='project-list-section'>
								<h4>
									{project.first_name}{' '}
									{project.last_name}
								</h4>
							</div>
							<div className='project-list-section'>
								<h4>{date}</h4>
							</div>
						</div>
						<div className='project-divider'>
							<div className='project-list-section'>
								<h4 name='currentEmployee'>
									{employeeName}
								</h4>
							</div>
							<div className='project-list-section'>
								<h4>{project.status}</h4>
							</div>
						</div>
						<div className='project-divider'>
							<div className='project-list-section'>
								<select
									className='status-drp'
									onChange={event =>
										onDevChangeHandler(
											event,
											project.project_id,
											project.employee_id
										)
									}>
									<option value=''>
										Update Developer
									</option>
									{employeeData.map(dev => (
										<option
											value={dev.employee_id}>
											{dev.first_name}{' '}
											{dev.last_name}
										</option>
									))}
								</select>
							</div>
							<div className='project-list-section'>
								<select
									className='status-drp'
									onChange={event =>
										onStatusChangeHandler(
											event,
											project.project_id
										)
									}
									defaultValue=''>
									<option id='update' value=''>
										Update Status
									</option>
									<option
										id='created'
										value='created'>
										Created
									</option>
									<option
										id='in progress'
										value='in progress'>
										In Progress
									</option>
									<option
										id='completed'
										value='completed'>
										Completed
									</option>
									<option id='closed' value='closed'>
										Closed
									</option>
								</select>
							</div>
						</div>
					</div>
				</li>
			);
		}
		return null;
	});

	return (
		<div className='projects'>
			<div className='projects-container'>
				<div className='projects-card'>
					<h2>Current Projects</h2>
					<div className='project-list-card'>
						<ul className='projects-list'>
							{currentProjectList}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Projects;
