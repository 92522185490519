import './CreateInvoices.css';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import CurrencyInput from 'react-currency-input-field';
import { AuthContext } from '../../../../../shared/context/auth-context';

const CreateInvoice = () => {
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const { sendRequest } = useHttpClient();
	const [projectData, setProjectData] = useState([]);
	const [clientData, setClientData] = useState([]);
	const [values, setValues] = useState({
		project_id: null,
		client_id: null,
		amount: null,
	});

	//get client and project data
	useEffect(() => {
		let responseData;
		const projectHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/projects/`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setProjectData(responseData.projects);
			} catch (error) {}
		};

		const clientHandler = async () => {
			try {
				responseData = await sendRequest(
					`https://decentralizedtech.org/api/customers`,
					'GET',
					null,
					{ Authorization: 'Bearer ' + auth.token }
				);
				setClientData(responseData.customers);
			} catch (error) {}
		};

		projectHandler();
		clientHandler();
	}, [sendRequest, auth.token]);

	//map projects and clients for dropdown form selection
	const mapClients = clientData.map(client => (
		<option key={client.customer_id} value={client.customer_id}>
			{client.first_name} {client.last_name}
		</option>
	));

	const mapProjects = projectData.map(project =>
		project.customer_id === values.client_id ? (
			<option key={project.project_id} value={project.project_id}>
				{project.project_name}
			</option>
		) : null
	);

	const onChangeHandler = event => {
		event.preventDefault();
		setValues({ ...values, [event.target.name]: event.target.value });
	};

	const addInvoiceHandler = async event => {
		event.preventDefault();
		let userId;
		try {
			userId = await sendRequest(
				`https://decentralizedtech.org/api/users/customerId/${values.client_id}`,
				'GET',
				null,
				{ Authorization: 'Bearer ' + auth.token }
			);
			userId = userId.userId;
		} catch (err) {}

		try {
			await sendRequest(
				`https://decentralizedtech.org/api/invoices/`,
				'POST',
				JSON.stringify({
					projectId: values.project_id,
					userId: userId, //what goes here? customer_id or user_id
					amount: values.amount,
				}),
				{
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + auth.token,
				}
			);
			navigate('/admin/invoices');
		} catch (err) {}
	};

	return (
		<div className='create-invoice'>
			<div className='create-invoice-container'>
				<div className='create-invoice-card'>
					<h2>Create Invoice</h2>
					<div className='invoice-form-container'>
						{/*add "required" to each input*/}
						<form
							className='create-invoice-form'
							onSubmit={addInvoiceHandler}>
							<div className='invoice-form-group invoice-form-group-one'>
								<div className='invoice-form-element'>
									<label
										className='in-label amount'
										htmlFor='invoiceName'>
										Invoice Amount:
									</label>
									<CurrencyInput
										className='invoice-formControl'
										id='invoiceAmount'
										name='amount'
										placeholder='Please enter amount'
										prefix='$'
										decimalsLimit={2}
										onChange={onChangeHandler}
									/>
								</div>
							</div>
							<div className='invoice-form-group invoice-form-group-three'>
								<div className='invoice-form-element-selector'>
									<label
										className='in-label selector'
										htmlFor='invoice-drp'>
										Select Client:
									</label>
									<select
										className='invoice-drp'
										name='client_id'
										onChange={onChangeHandler}>
										<option value=''>
											Select...
										</option>
										{mapClients}
									</select>
								</div>
								<div className='invoice-form-element-selector'>
									<label
										className='in-label selector'
										htmlFor='invoice-drp'>
										Select Project:
									</label>
									<select
										className='invoice-drp'
										name='project_id'
										onChange={onChangeHandler}>
										<option value=''>
											Select...
										</option>
										{mapProjects}
									</select>
								</div>
							</div>
							<div className='invoice-form-group'>
								<Link
									to='/admin/invoices'
									className='dashboard-btn'>
									Cancel
								</Link>
								<button
									className='dashboard-btn'
									type='submit'
									onClick={addInvoiceHandler}>
									Add
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateInvoice;
